import './App.scss'
import * as Actions from 'actions/content'
import React, { Component } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import {
  faComment,
  faEnvelope,
  faMapMarkerAlt,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter,
  faWhatsapp,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import GamePage from './pages/page-game'
import NewsletterModal from './organisms/NewsletterModal'
import PageContainer from './pages/page-default'
import PageContainerNewsletter from './pages/page-newsletter'
import PageContainerDECustom from './pages/page-de-custom'
import PageContainerUSCustom from './pages/page-us-custom'
import PageContainerEnjoySlow from './pages/page-enjoyslow'
import PageContainerWTB from './pages/page-where-to-buy'
import PageNfl2023 from './pages/page-nfl-2023'
import PageMap from './pages/page-map'
import PageRsvpComp from './pages/page-rsvp-comp'
import PageHalloween2023 from './pages/page-halloween-2023'
import PageRsvpCompP3 from './pages/page-rsvp-comp-p3'
import PageRsvpCompP7 from './pages/page-rsvp-comp-p7'
import PageRsvpCompP8 from './pages/page-rsvp-comp-p8'
import PageRsvpCompP72 from './pages/page-rsvp-comp-p7-2'
import PageContainerDrinksWorks from './pages/page-drinks-works'
import PageContactUs from './pages/page-contact-us'
import PreviewPage from './pages/pages-preview'
import ScrollToTop from 'global/utils/ScrollToTop'
import * as routeList from 'global/utils/routing'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { faSadTear } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import withConfig from 'hoc/with-config'

import rsvpBg from 'global/images/comp2023/att_stadium.jpg'
import rsvpBg2 from 'global/images/comp2023/stadium_seats--red.jpg'
import rsvpBg3 from 'global/images/comp2023/house_party_bg.jpg'
import rsvpBg4 from 'global/images/comp2023/stadium_seats--red.jpg'
import rsvpBg5 from 'global/images/comp2023/action_bg.jpg'

class App extends Component {
  cleanUrl = (url) =>
    url
      .replace(new RegExp('https:/www.drinkiq.com/en-us/', 'g'), '')
      .replace(new RegExp('https:/www.where-to-buy.com/', 'g'), '')
      .replace(
        new RegExp(
          'https:/captainmorganstore.com/store.php/product-detail/captain-morgan-custom-label',
          'g'
        ),
        ''
      )
      .replace(new RegExp('https:/www.captainmorganstore.com/', 'g'), '')

  fetchData = (page) => {
    if (!page.name) {
      return
    }

    const url = `${this.cleanUrl(`${this.props.config.apiUrl}${page.name.toLowerCase()}`).replace(
      /\/+$/,
      ''
    )}.json`

    this.props.actions.fetchContent({
      apiUrl: this.props.config.apiUrl,
      page,
      url
    })
  }

  render() {
    const { content } = this.props
    const { defaultMarket } = this.props.config
    const gameRoutes = routeList.getRouteList()

    // Define FontAwesome library once here and consume later

    library.add(
      faFacebookF,
      faPinterestP,
      faTwitter,
      faWhatsapp,
      faInstagram,
      faYoutube,
      faComment,
      faEnvelope,
      faSadTear,
      faThumbsUp,
      faMapMarkerAlt
    )
    const isServer = typeof window === 'undefined'

    return (
      <ScrollToTop>
        <div className="app-inner">
          {!isServer && (
            <>
              {window.location.pathname.includes('en-us') &&
                !window.location.pathname.includes('newsletter') && <NewsletterModal />}
            </>
          )}

          <Switch>
            <Route
              path={'/en-gb/enjoyslow'}
              render={(props) => (
                <PageContainerEnjoySlow
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              path={'/en-za/enjoyslow'}
              render={(props) => (
                <PageContainerEnjoySlow
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              path={'/de-de/was-ist-dein-spice'}
              render={(props) => (
                <PageContainerDECustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />

            {/* DRINKS WORKS */}
            <Route
              exact
              path={'/de-de/drinksworks'}
              render={(props) => (
                <PageContainerDrinksWorks
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'de-de-drinksworks'}
            />

            <Route
              path={'/en-us/a-taste-of-the-tropics'}
              render={(props) => (
                <PageContainerUSCustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            {/*<Route
                            path={"/en-us/a-taste-of-the-tropics/jamaicaqueens"}
                            render={(props) => <PageContainerUSCustom
                                data={content}
                                fetchData={this.fetchData}
                                location={props.location}
                            />}
                        />
                        <Route
                            path={"/en-us/a-taste-of-the-tropics/sanjuancapistrano"}
                            render={(props) => <PageContainerUSCustom
                                data={content}
                                fetchData={this.fetchData}
                                location={props.location}
                            />}
                        />*/}
            <Route
              path={'/en-us/a-taste-of-the-tropics/jamaicaplain'}
              render={(props) => (
                <PageContainerUSCustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              path={'/en-us/newsletter'}
              render={(props) => (
                <PageContainerNewsletter
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              path={'/en-us/where-to-buy/shop-online'}
              render={(props) => (
                <PageContainerWTB
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              path={'/en-us/where-to-buy/find-near-me'}
              render={(props) => (
                <PageContainerWTB
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
            <Route
              exact
              path="/"
              render={() => <Redirect exact from="/" to={`/${defaultMarket}/`} />}
            />

            <Route
              exact
              path="/preview"
              render={(props) => (
                <PreviewPage data={content} fetchData={this.fetchData} location={props.location} />
              )}
            />
            <Route
              exact
              path="/followthecaptain"
              render={(props) => (
                <PageNfl2023 data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'nfl-2023'}
            />
            <Route
              exact
              path="/followthecaptain/gasparilla"
              render={(props) => (
                <PageMap data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'gasparilla'}
            />
            {/*<Route
              exact
              path={'/followthecaptain/rsvp'}
              render={(props) => (
                <PageRsvpComp
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp20231"
                  herobg={rsvpBg}
                />
              )}
              key={'nfl-2023-rsvp-1'}
            />*/}
            {/*<Route
              exact
              path={'/followthecaptain/the-captains-section-rsvp'}
              render={(props) => (
                <PageRsvpCompP7
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp202341"
                  herobg={rsvpBg4}
                />
              )}
              key={'nfl-2023-the-captains-section-rsvp'}
            />*/}
            {/*<Route
              exact
              path={'/followthecaptain/battle-of-the-captains'}
              render={(props) => (
                <PageRsvpCompP8
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp202351"
                  herobg={rsvpBg5}
                />
              )}
              key={'nfl-2023-rivalry-watch-party-rsvp'}
            />*/}
            {/*<Route
              exact
              path={'/followthecaptain/the-captains-section-rsvp-vip'}
              render={(props) => (
                <PageRsvpCompP72
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp202342"
                  herobg={rsvpBg4}
                />
              )}
              key={'nfl-2023-the-captains-section-rsvp-vip'}
            />*/}
            {/* HALLOWEEN 2023 CONSUMER */}
            <Route
              exact
              path={'/en-gb/onehellofacocktail/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023cs"
                  pagetype="closed"
                />
              )}
              key={'hw-2023-cs'}
            />
           <Route
              exact
              path={'/en-gb/onehellofacocktail/terms/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023cs"
                  pagetype="terms"
                />
              )}
              key={'hw-2023-cs-terms'}
            />
            <Route
              exact
              path={'/en-gb/onehellofacocktail/thanks/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023cs"
                  pagetype="thanks"
                />
              )}
              key={'hw-2023-cs-thanks'}
            />
           {/* <Route
              exact
              path={'/en-gb/onehellofacocktail/closed/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023cs"
                  pagetype="closed"
                />
              )}
              key={'hw-2023-cs-closed'}
            />
            <Route
              exact
              path={'/en-gb/onehellofacocktail/holding/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023cs"
                  pagetype="holding"
                />
              )}
              key={'hw-2023-cs-holding'}
            />*/}

            {/* HALLOWEEN 2023 WHOLESALE */}
            <Route
              exact
              path={'/en-gb/ws-onehellofacocktail/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023ws"
                  pagetype="closed"
                />
              )}
              key={'hw-2023-ws'}
            />
            <Route
              exact
              path={'/en-gb/ws-onehellofacocktail/terms/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023ws"
                  pagetype="terms"
                />
              )}
              key={'hw-2023-ws-terms'}
            />
            {/*<Route
              exact
              path={'/en-gb/ws-onehellofacocktail/thanks/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023ws"
                  pagetype="thanks"
                />
              )}
              key={'hw-2023-ws-thanks'}
            />
            <Route
              exact
              path={'/en-gb/ws-onehellofacocktail/closed/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023ws"
                  pagetype="closed"
                />
              )}
              key={'hw-2023-ws-closed'}
            />
            <Route
              exact
              path={'/en-gb/ws-onehellofacocktail/holding/'}
              render={(props) => (
                <PageHalloween2023
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="hw2023ws"
                  pagetype="holding"
                />
              )}
              key={'hw-2023-ws-holding'}
            />*/}
            {/* <Route
              exact
              path={'/followthecaptain/captains-corner-rsvp'}
              render={(props) => (
                <PageRsvpCompP3
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp20232"
                  herobg={rsvpBg2}
                />
              )}
              key={'nfl-2023-rsvp-2'}
            /> */}

            {/*<Route
              exact
              path={'/followthecaptain/vics-house-party'}
              render={(props) => (
                <PageRsvpCompP5
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                  compname="nflrsvp20233"
                  herobg={rsvpBg3}
                />
              )}
              key={'nfl-2023-rsvp-3'}
            />*/}

            {/*** CONTACT US ***/}
            <Route
              exact
              path={'/en-us/contact-us'}
              render={(props) => (
                <PageContactUs
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'contact-us-en-us'}
            />

            {gameRoutes &&
              gameRoutes.length > 0 &&
              gameRoutes.map((gameRoute, i) => (
                <Route
                  path={gameRoute}
                  render={(props) => (
                    <GamePage
                      data={content}
                      fetchData={this.fetchData}
                      location={props.location}
                      key={`gameRoute_${i}`}
                    />
                  )}
                />
              ))}

            <Route
              path={'/'}
              render={(props) => (
                <PageContainer
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
            />
          </Switch>
        </div>
      </ScrollToTop>
    )
  }
}

const mapStateToProps = (state) => ({ content: state.content.pages || {} })

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(Actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withConfig(App))
