import "./hero-halloween.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";

import bottleDeco from 'global/images/halloween2023/hero_bottles.png'
import whiteLogo from 'global/images/halloween2023/logo_white.png'
import csLogo from 'global/images/halloween2023/consumer_logo.png'
import wsLogo from 'global/images/halloween2023/wholesale_logo.png'


const HeroHalloween = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const heroData = getData(pageType, compName)
  let heroLogo = csLogo

  if(compName === 'hw2023ws') {
    heroLogo = wsLogo
  }

  function getData (pageType, compName) {
    const data = [
      {
        comp: 'hw2023cs',
        data: [
          {
            pagetype: 'form',
            copy: 'Enter your details below for the chance to win'
          },
          {
            pagetype: 'thanks',
            copy: 'Thanks for Entering!'
          },
          {
            pagetype: 'holding',
            copy: 'You’re too early!'
          },
          {
            pagetype: 'closed',
            copy: 'You’re too late!'
          },
          {
            pagetype: 'terms',
            copy: 'Terms & Conditions'
          }
        ]
      },
      {
        comp: 'hw2023ws',
        data: [
          {
            pagetype: 'form',
            copy: 'Enter your details below for the chance to win'
          },
          {
            pagetype: 'thanks',
            copy: 'Thanks for Entering!'
          },
          {
            pagetype: 'holding',
            copy: 'You’re too early!'
          },
          {
            pagetype: 'closed',
            copy: 'You’re too late!'
          },
          {
            pagetype: 'terms',
            copy: 'Terms & Conditions'
          }
        ]
      }
    ]

    if(pageType !== '' && compName !== '') {
      const heroData = data.filter(x => x.comp === compName)[0]
      const heroItems = heroData.data.filter(y => y.pagetype === pageType)[0]

      if(heroItems) {
        return heroItems
      }else{
        return null
      }
    }else{
      return null
    }
  }
  useEffect(() => {

  })
  
  return (
    <section className="hw2023hero">
      <div className="hw2023hero__wrap flex">
        <div className="hw2023hero__topdeco">
          <div className="hw2023hero__logo">
            <a href="/en-gb/" title="Home Page" aria-label="Home Page">
              <img src={whiteLogo} alt="Top decoration" />
            </a>
          </div>
        </div>
        <div className="container container--1000 hw2023hero__content">
          <div className="hw2023hero__middeco">
            <img src={bottleDeco} alt="Bottle decoration" aria-hidden="true" />
          </div>
          <div className="hw2023hero__image">
            <img src={heroLogo} alt="One Hell of A Cocktail - Win 6666" />
          </div>
          <h1 className="hw2023hero__copy">
            {heroData && heroData.copy &&
              heroData.copy
            }
          </h1>
        </div>
      </div>
    </section>
  );
};

export default HeroHalloween;
