import "./content-form.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";
import {Helmet} from 'react-helmet'
import moment from 'moment'
import {load} from "recaptcha-v3";

const ContentCSForm = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const [date, setDate] = useState("");

  function handleNumChange (e) {
    let key = e.key
    if(key.toLowerCase() !== 'backspace' && key.toLowerCase() !== 'tab'){
      if (isNaN(key)) {
        e.preventDefault()
      }
    }else{
      return true
    }
  };
  function checkAge (dob) {

    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);
    if(dob.indexOf('-') === 4){
      if (moment().diff(birthday, "years", false) < 18) {
        return true
      } else {
        return false
      }
    }else{
      console.log('THIS SHOULD SEND AN ERROR')
      return true
    }
  }
 
  // eslint-disable-next-line complexity
  async function formSubmit(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#cidbSubmit').prop('disabled', true).addClass('disabled')
    $('#errorMessage').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });

    event.preventDefault()

    const fname = $("#CP_FirstName").val();
    const lname = $("#CP_LastName").val();
    const email = $("#CP_EmailId").val();
    const phone = $("#CP_PhoneNumber").val();
    const barcode = $("#PR_4_90_1_freetext").val();
    const tc = $("#tc").prop("checked");
    const checkboxFields = $("#PR_1_64_1").prop("checked");
    let captcha = "";

    // format dob 
    const dobArr = $('#CP_DOB').val().split('/')
    const newDob = dobArr[1] + '/' + dobArr[0] + '/' + dobArr[2]
    const dob =  moment(newDob).format("YYYY-MM-DD")
    // console.log("form submit >>> ", fname);
    // console.log("form submit >>> PR_4_466_1_freetext ", PR_4_466_1_freetext);
    //check required fields are present
    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        || phone.trim() === ""
        || barcode.trim() === ""
        || dob.trim() === "" 
        || tc === false) {

      if (fname.trim() === "") {
        $("#CP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#CP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#CP_EmailId").addClass("error");
      }
      if (phone.trim() === "") {
        $("#CP_PhoneNumber").addClass("error");
      }
      if (dob.trim() === "") {
        $("#CP_DOB").addClass("error");
      }
      if (barcode.trim() === "") {
        $("#PR_4_90_1_freetext").addClass("error");
      }
      formError = true
    }
    //check age
    ageError = checkAge(dob)
    // console.log('formError >>>> ', formError)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('form__hw2023cs'));
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
            // eslint-disable-next-line radix
            send[key] = parseInt(value);
        } else if (key === "PR_4_92_1" || key === "tc" || key === "PR_1_64_1") {
          if (value === "on") {
              send[key] = "1";
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "CP_DOB") {
          send[key] = dob
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          send[key] = value;
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const res = await fetch(`/api/cidbcomp/add`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          // console.log('RES >>>> ', res)
          if(res && res.success) {
            window.location.href = "/en-gb/onehellofacocktail/thanks/"
          }else{
            let message = 'Something went wrong. Please try again.'

            if(res.msg) {
              message = res.msg
            }
            $('#errorMessage').html(`<div>${message}</div>`)
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          if($('#cidbSubmit')) {
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        // console.log('form errors')
        let errorMsg = "";
        
        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || phone.trim() === ""
          || barcode.trim() === ""
          || dob.trim() === "") {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 18 and over to enter.</div>";
        }

        if (tc === false) {
          errorMsg += "<div>Please accept the terms and conditions.</div>";
        }
        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
        $('#errorMessage').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  const handleDateChange = (e) => {
    let value = e.target.value;
    let backsapce = date.length > value.length;

    if (!backsapce && (date.length == 1 || date.length == 4)) {
      value += "/";
    }
    setDate(value);
  };

  useEffect(() => {
    $(function () {
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $("input.input, select.input").on("focusin", function () {
       
        $(this).siblings(".inputlabel").removeClass("inputlabel--empty");
      });

      $("input.input, select.input").on("focusout change", function () {
        if ($(this).val() === "") {
          $(this).siblings(".inputlabel").addClass("inputlabel--empty");
        }
      });
    })
  })
  return (
    <div className="hw2023content__form form">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
      </Helmet>
      <div className="container container--1200">
        <div className="form__wrap">
          <form id="form__hw2023cs" runat="server" action="/api/CIDBapi_Form">
            <input id="PR_4_367_1_freetext" name="PR_4_367_1_freetext" type="hidden" placeholder="" runat="server"value="" />
            <input id="HD_PromoCode" name="HD_PromoCode" runat="server" type="hidden" value={'DWE028CPT0823AA0021'} />

            <input id="HD_Consumerid" name="HD_Consumerid" runat="server" type="hidden" value="" />

            <div className="inputWrapper labeled half">
              <label htmlFor="CP_FirstName" className="inputlabel inputlabel--empty">FIRST NAME *</label>
              <input id="CP_FirstName" name="CP_FirstName" type="text" className="input" required />
            </div>

            <div className="inputWrapper labeled half">
              <label htmlFor="CP_LastName" className="inputlabel inputlabel--empty">LAST NAME *</label>
              <input id="CP_LastName" name="CP_LastName" type="text" className="input" required />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_EmailId" className="inputlabel inputlabel--empty">EMAIL*</label>
              <input id="CP_EmailId" name="CP_EmailId" type="email" className="input" required />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_PhoneNumber" className="inputlabel inputlabel--empty">MOBILE NUMBER *</label>
              <input
                type="text"
                name="CP_PhoneNumber"
                id="CP_PhoneNumber"
                className="input"
                maxLength={11}
                onKeyUp={e => handleNumChange(e)}
                onKeyDown={e => handleNumChange(e)}
                />
                <input
                id="HD_PhoneType"
                name="HD_PhoneType"
                type="hidden"
                value="3"
                />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_DOB" className="inputlabel inputlabel--empty">DATE OF BIRTH *</label>
              <input 
                id="CP_DOB"
                name="CP_DOB"
                type="text"
                value={date}
                maxLength={10}
                onChange={e=> handleDateChange(e)}
                placeholder="dd/mm/yyyy"
                className="input"
                required />
            </div>
            <div className="inputWrapper labeled">
              <label htmlFor="PR_4_90_1_freetext" className="inputlabel inputlabel--empty">Last 4 digits of barcode *</label>
              <input id="PR_4_90_1_freetext" name="PR_4_90_1_freetext" type="text" className="input" maxLength={4} required />
            </div>

            <div className="inputWrapper checkbox ptop">
              <input id="tc" name="tc" type="checkbox" required />
              <label htmlFor="tc" dangerouslySetInnerHTML={{
                __html: `I confirm that I am over 18 years old and agree to the promotional <a href="/en-gb/onehellofacocktail/terms" target="_blank">terms and conditions</a> *`
                }}
              />
            </div>
            
            <div className="inputWrapper checkbox">
              <input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" required />
              <label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                __html: `Tick here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.`
                }}
              />
            </div>
            <div className="inputWrapper checkbox">
              <div className="form__text">
                * Mandatory Fields
              </div>
            </div>
              <div className="inputWrapper">
                  <div id="recaptcha"></div>
              </div>

              <div className="inputWrapper">
                  <div className="errorMessage" id="errorMessage"></div>
              </div>

            <div className="inputWrapper">
              <input id="compname" name="compname" runat="server" type="hidden" value={compName} />
              <input id="HD_IsDefaultFlag" name="HD_IsDefaultFlag" runat="server" type="hidden" value="1" />
              <input id="HD_PR_1_64_1_Brand" name="HD_PR_1_64_1_Brand" runat="server" type="hidden" value="4" />
              <input id="HD_PR_1_64_1_Comm" name="HD_PR_1_64_1_Comm" runat="server" type="hidden" value="1" />
              <input id="HD_AddressType" name="HD_AddressType" runat="server" type="hidden" value="1" />
              <input id="CP_WebsiteUrl" name="CP_WebsiteUrl" runat="server" type="hidden" value="https://www.captainmorgan.com/" />

              <input className="submit btn btn--bgredg btn--textwhite" type="submit" value="ENTER" id='cidbSubmit' onClick={(evnt) => formSubmit(evnt)} />
            </div>
            <div className="inputWrapper copy disclaimer">
              <small dangerouslySetInnerHTML={{
              __html: `GB, 18+. Internet access, mobile phone and purchase of any Captain Morgan bottle required for entry. Bank account required for claim. Scan QR code or visit <a href="https://www.captainmorgan.com/en-gb/onehellofacocktail">https://www.captainmorgan.com/en-gb/onehellofacocktail</a> to enter. Opens 00:00 29.09.23 – 23:59 03.11.23. 1 prize draw for a chance to win £6,666 cash prize. One entry per mobile number per week. For a full list of qualifying products, prize details & terms: <a href="https://www.captainmorgan.com/en-gb/onehellofacocktail">https://www.captainmorgan.com/en-gb/onehellofacocktail</a>. It is recommended that you take a photo/ copy of the full Terms & Conditions for your reference. Promoter: Diageo, W1F 7HS`
              }}
            />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentCSForm;
