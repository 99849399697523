/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import buildPage from "../page-builder";

import HeroHalloween from "../../organisms/hero-halloween";
import ContentHalloween from "../../organisms/content-halloween";
import FooterHalloween from "../../organisms/footer-halloween";

export default class PageContainer extends Component {

  state = {
    "isLoaded": false,
    "setRoutes": false,
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }

  componentDidMount () {
    
  }

  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const compName = this.props && this.props.compname ? this.props.compname : ''
    const pageType = this.props && this.props.pagetype ? this.props.pagetype : ''

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    page.headerComponents[0].props.fields[0].value = "One Hell of a Cocktail | Captain Morgan";
    page.headerComponents[0].props.fields[4].value = "200";
    
    return (
      <div className="page hw2023">
        {page.headerComponents}
        <main className="page__content" id="main" role="main">
          <HeroHalloween compname={compName} pagetype={pageType} />
          <ContentHalloween compname={compName} pagetype={pageType} />
          <section className="hw2023__section hw2023footer footer">
            <FooterHalloween pagetype={pageType} compname={compName} />
            <footer className="page__footer" role="contentinfo">
              {page.footerComponents}
            </footer>
          </section>
        </main>
      </div>
    );
  }
}
