import "./content-halloween.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";

import ContentTerms from "./content-terms";
import ContentThanks from "./content-thanks";
import ContentCSForm from "./content-csform";
import ContentWSForm from "./content-wsform";

const ContentHalloween = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const contentData = getData(pageType, compName)
  
  function getData (pageType, compName) {
    const data = [
      {
        comp: 'hw2023cs',
        data: [
          {
            pagetype: 'form',
            copy: 'Enter your details below for the chance to win'
          },
          {
            pagetype: 'thanks',
            title: 'Thanks, we’ve received your entry.<br />The winner will be notified by phone by 17th November 2023.',
            copy: 'In the meantime, why not try making one of our recipes!',
            subcopy: 'MAKE SURE TO SHARE YOUR CONCOCTION WITH US ON SOCIAL MEDIA! USE THE HASHTAG #SPICEON and TAG US @CAPTAINMORGANgb',
            videos: [
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
            ]
          },
          {
            pagetype: 'holding',
            title: 'PROMOTION COMING SOON!',
            copy: 'The Captain Morgan One Hell of a Cocktail promotion opens at 00:00 on 29th September 2023. Please come back then for your chance to win £6,666'
          },
          {
            pagetype: 'closed',
            title: 'SORRY, THIS PROMOTION IS CLOSED.',
            copy: 'The Captain Morgan One Hell of a Cocktail promotion closed for entries at 23:59 on 3rd November 2023.'
          },
          {
            pagetype: 'terms',
            copy: 'Terms & Conditions'
          }
        ]
      },
      {
        comp: 'hw2023ws',
        data: [
          {
            pagetype: 'form',
            copy: 'Enter your details below for the chance to win'
          },
          {
            pagetype: 'thanks',
            title: 'Thanks, we’ve received your entry.<br />The winner will be notified by phone by 17th November 2023.',
            copy: 'In the meantime, why not try making one of our recipes!',
            subcopy: 'MAKE SURE TO SHARE YOUR CONCOCTION WITH US ON SOCIAL MEDIA! USE THE HASHTAG #SPICEON and TAG US @CAPTAINMORGANgb',
            videos: [
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
              'https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0',
            ]
          },
          {
            pagetype: 'holding',
            title: 'PROMOTION COMING SOON!',
            copy: 'The Captain Morgan One Hell of a Cocktail promotion opens at 00:00 on 29th September 2023. Please come back then for your chance to win £6,666'
          },
          {
            pagetype: 'closed',
            title: 'SORRY, THIS PROMOTION IS CLOSED.',
            copy: 'The Captain Morgan One Hell of a Cocktail promotion closed for entries at 23:59 on 1st November 2023.'
          },
          {
            pagetype: 'terms',
            copy: 'Terms & Conditions'
          }
        ]
      }
    ]

    if(pageType !== '' && compName !== '') {
      const heroData = data.filter(x => x.comp === compName)[0]
      const heroItems = heroData.data.filter(y => y.pagetype === pageType)[0]
      if(heroItems) {
        return heroItems
      }else{
        return null
      }
    }else{
      return null
    }
  }
  useEffect(() => {

  })

  return (
    <section className="hw2023content">
      <div className="hw2023content__bottomdeco">
      </div>
      <div className="hw2023content__wrap flex">
        <div className="container container--1920">
          {(pageType === 'holding' || pageType === 'closed') &&
            <div className="hw2023content__generic generic">
              <h2 className="generic__title" dangerouslySetInnerHTML={{
                __html: `${contentData.title}`
              }} />
              <div className="generic__copy" dangerouslySetInnerHTML={{
                __html: `${contentData.copy}`
              }} />
            </div>
          }
          {pageType === 'terms' &&
            <ContentTerms pagetype={pageType} compname={compName} />
          }
          {pageType === 'thanks' &&
            <ContentThanks pagetype={pageType} compname={compName} />
          }
          {(pageType === 'form' && compName === 'hw2023cs') &&
            <ContentCSForm pagetype={pageType} compname={compName} />
          }
          {(pageType === 'form' && compName === 'hw2023ws') &&
            <ContentWSForm pagetype={pageType} compname={compName} />
          }
        </div>
      </div>
    </section>
  );
};

export default ContentHalloween;
