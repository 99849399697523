import "./footer-halloween.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";

import footerLogo from 'global/images/halloween2023/footer_logo.png'

const FooterHalloween = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const footerLinks = getLinks(pageType, compName)

  function getLinks (pageType, compName) {
    const data = [
      {
        comp: 'hw2023cs',
        data: [
          {
            pagetype: 'form',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'thanks',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'holding',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'closed',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'terms',
            links: [
              {
                title: 'Recipes',
                url: '/en-gb/cocktails/'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          }
        ]
      },
      {
        comp: 'hw2023ws',
        data: [
          {
            pagetype: 'form',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/ws-onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'thanks',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/ws-onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'holding',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/ws-onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'closed',
            links: [
              {
                title: 'Terms & Conditions',
                url: '/en-gb/ws-onehellofacocktail/terms'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          },
          {
            pagetype: 'terms',
            links: [
              {
                title: 'Recipes',
                url: '/en-gb/cocktails/'
              },
              {
                title: 'Privacy Policy',
                url: 'https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb'
              }
            ]
          }
        ]
      }
    ]

    if(pageType !== '' && compName !== '') {
      const footerData = data.filter(x => x.comp === compName)[0]
      const footerLinks = footerData.data.filter(y => y.pagetype === pageType)[0]

      if(footerLinks && footerLinks.links && footerLinks.links.length > 0){
        return footerLinks.links
      }else{
        return null
      }
    }else{
      return null
    }
  }
  useEffect(() => {

  })

  return (
    <div className="footercustom">
      <div className="footercustom__wrap flex">
        <div className="container container--1440">
          {footerLinks && footerLinks.length > 0 &&
            <div className="footercustom__links">
              {footerLinks && footerLinks.map((link, x) => {
                return (
                  <a key={`footerCustomLink__${x}`} href={link && link.url ? link.url : '#'} className="footercustom__link btn btn--bgredg btn--textwhite">
                    {link && link.title ? link.title : "Link"}
                  </a>
                )
              })}
            </div>
          }
          
          <div className="footercustom__logo">
            <img src={footerLogo} alt="One Hell of a cocktail logo" />
          </div>
          <div className="footercustom__copyright">
            &copy; 2023 Captain Morgan
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterHalloween;
