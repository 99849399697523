import "./content-halloween.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";


const ContentThanks = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const contentData = getData(pageType, compName)
  
  function getData (pageType, compName) {
    const data = [
      {
        comp: 'hw2023cs',
        data: [
          {
            pagetype: 'thanks',
            title: 'Thanks, we’ve received your entry.<br />The winner will be notified by phone by 17th November 2023.',
            copy: 'In the meantime, why not try making one of our recipes!',
            subcopy: 'MAKE SURE TO SHARE YOUR CONCOCTION WITH US ON SOCIAL MEDIA! USE THE HASHTAG #SPICEON and TAG US @CAPTAINMORGANgb',
            videos: [
              {
                url: 'https://player.vimeo.com/video/867968472?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Captain Morgan Bucket of Blood'
              },
              {
                url: 'https://player.vimeo.com/video/867968767?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Sliced and Iced'
              },
              {
                url: 'https://player.vimeo.com/video/867969039?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Stormy Zombie'
              },
              {
                url: 'https://player.vimeo.com/video/867969340?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Freaky Tiki'
              },
            ]
          },
        ]
      },
      {
        comp: 'hw2023ws',
        data: [
          {
            pagetype: 'thanks',
            title: 'Thanks, we’ve received your entry.<br />The winner will be notified by phone by 17th November 2023.',
            copy: 'In the meantime, why not try making one of our recipes!',
            subcopy: 'MAKE SURE TO SHARE YOUR CONCOCTION WITH US ON SOCIAL MEDIA! USE THE HASHTAG #SPICEON and TAG US @CAPTAINMORGANgb',
            videos: [
              {
                url: 'https://player.vimeo.com/video/867968472?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Captain Morgan Bucket of Blood'
              },
              {
                url: 'https://player.vimeo.com/video/867968767?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Sliced and Iced'
              },
              {
                url: 'https://player.vimeo.com/video/867969039?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Stormy Zombie'
              },
              {
                url: 'https://player.vimeo.com/video/867969340?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22',
                title: 'Freaky Tiki'
              },
            ]
          },
        ]
      }
    ]

    if(pageType !== '' && compName !== '') {
      const heroData = data.filter(x => x.comp === compName)[0]
      const heroItems = heroData.data.filter(y => y.pagetype === pageType)[0]
      if(heroItems) {
        return heroItems
      }else{
        return null
      }
    }else{
      return null
    }
  }
  useEffect(() => {

  })
  return (
    <div className="hw2023content__thanks thanks">
      <div className="container container--1000">
        <div className="thanks__copy">
          {contentData && contentData.title &&
            <h2 className="thanks__title" dangerouslySetInnerHTML={{
              __html: `${contentData.title}`
            }} />
          }        
          <div className="thanks__maincopy">
            {contentData && contentData.copy &&
              contentData.copy
            }
          </div>
        </div>
      </div>
      <div className="thanks__grid videogrid">
        {contentData && contentData.videos && contentData.videos.length > 0 &&
          contentData.videos.map( (vid, x) => {
            return (
              <div key={`${compName}_video_${x}`} className="videogrid__item">
                <iframe id={`${compName}_video_${x}`} allow="autoplay" title={vid.title} aria-label={vid.title} src={vid.url} />
              </div>
            )
          })
        }
        
      </div>
      {contentData && contentData.subcopy &&
        <div className="container container--1000">
          <div className="thanks__endcopy">
            {contentData.subcopy}
          </div>
        </div>
      }
    </div>
  );
};

export default ContentThanks;
