import "./content-gallery.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";


import $ from 'jquery'

import sliderImage1 from 'global/images/comp2023/prize_package_2.jpg'
import sliderImage2 from 'global/images/comp2023/prize_jersey_2.jpg'
import sliderImage3 from 'global/images/comp2023/prize_cooler_2.jpg'

/*** EVENT 1 IMAGES ***/
import event11 from 'global/images/comp2023/highlights/event-1-1.jpg'
import event12 from 'global/images/comp2023/highlights/event-1-2.jpg'
import event13 from 'global/images/comp2023/highlights/event-1-3.jpg'
import event14 from 'global/images/comp2023/highlights/event-1-4.jpg'
import event15 from 'global/images/comp2023/highlights/event-1-5.jpg'
import event16 from 'global/images/comp2023/highlights/event-1-6.jpg'
import event17 from 'global/images/comp2023/highlights/event-1-7.jpg'
import event18 from 'global/images/comp2023/highlights/event-1-8.jpg'
import event19 from 'global/images/comp2023/highlights/event-1-9.jpg'
import event110 from 'global/images/comp2023/highlights/event-1-10.jpg'
import event111 from 'global/images/comp2023/highlights/event-1-11.jpg'
import event112 from 'global/images/comp2023/highlights/event-1-12.jpg'
import event113 from 'global/images/comp2023/highlights/event-1-13.jpg'
import event114 from 'global/images/comp2023/highlights/event-1-14.jpg'
import event115 from 'global/images/comp2023/highlights/event-1-15.jpg'
import event116 from 'global/images/comp2023/highlights/event-1-16.jpg'
import event117 from 'global/images/comp2023/highlights/event-1-17.jpg'
import event118 from 'global/images/comp2023/highlights/event-1-18.jpg'
/*** END EVENT 1 IMAGES ***/

/*** EVENT 2 IMAGES ***/
import event21 from 'global/images/comp2023/highlights/event-2-1.jpg'
import event22 from 'global/images/comp2023/highlights/event-2-2.jpg'
import event23 from 'global/images/comp2023/highlights/event-2-3.jpg'
import event24 from 'global/images/comp2023/highlights/event-2-4.jpg'
import event25 from 'global/images/comp2023/highlights/event-2-5.jpg'
import event26 from 'global/images/comp2023/highlights/event-2-6.jpg'
import event27 from 'global/images/comp2023/highlights/event-2-7.jpg'
import event28 from 'global/images/comp2023/highlights/event-2-8.jpg'
import event29 from 'global/images/comp2023/highlights/event-2-9.jpg'
import event210 from 'global/images/comp2023/highlights/event-2-10.jpg'
import event211 from 'global/images/comp2023/highlights/event-2-11.jpg'
import event212 from 'global/images/comp2023/highlights/event-2-12.jpg'
import event213 from 'global/images/comp2023/highlights/event-2-13.jpg'
import event214 from 'global/images/comp2023/highlights/event-2-14.jpg'
/*** END EVENT 2 IMAGES ***/

/*** EVENT 3 IMAGES ***/
import event31 from 'global/images/comp2023/highlights/event-3-1.jpg'
import event32 from 'global/images/comp2023/highlights/event-3-2.jpg'
import event33 from 'global/images/comp2023/highlights/event-3-3.jpg'
import event34 from 'global/images/comp2023/highlights/event-3-4.jpg'
import event35 from 'global/images/comp2023/highlights/event-3-5.jpg'
import event36 from 'global/images/comp2023/highlights/event-3-6.jpg'
import event37 from 'global/images/comp2023/highlights/event-3-7.jpg'
import event38 from 'global/images/comp2023/highlights/event-3-8.jpg'
import event39 from 'global/images/comp2023/highlights/event-3-9.jpg'
import event310 from 'global/images/comp2023/highlights/event-3-10.jpg'
import event311 from 'global/images/comp2023/highlights/event-3-11.jpg'
import event312 from 'global/images/comp2023/highlights/event-3-12.jpg'
import event313 from 'global/images/comp2023/highlights/event-3-13.jpg'
import event314 from 'global/images/comp2023/highlights/event-3-14.jpg'
import event315 from 'global/images/comp2023/highlights/event-3-15.jpg'
import event316 from 'global/images/comp2023/highlights/event-3-16.jpg'
import event317 from 'global/images/comp2023/highlights/event-3-17.jpg'
/*** END EVENT 3 IMAGES ***/

/*** EVENT 4 IMAGES ***/
import event41 from 'global/images/comp2023/highlights/event-4-1.jpg'
import event42 from 'global/images/comp2023/highlights/event-4-2.jpg'
import event43 from 'global/images/comp2023/highlights/event-4-3.jpg'
import event44 from 'global/images/comp2023/highlights/event-4-4.jpg'
import event45 from 'global/images/comp2023/highlights/event-4-5.jpg'
import event46 from 'global/images/comp2023/highlights/event-4-6.jpg'
import event47 from 'global/images/comp2023/highlights/event-4-7.jpg'
import event48 from 'global/images/comp2023/highlights/event-4-8.jpg'
import event49 from 'global/images/comp2023/highlights/event-4-9.jpg'
import event410 from 'global/images/comp2023/highlights/event-4-10.jpg'
import event411 from 'global/images/comp2023/highlights/event-4-11.jpg'
import event412 from 'global/images/comp2023/highlights/event-4-12.jpg'
import event413 from 'global/images/comp2023/highlights/event-4-13.jpg'
import event414 from 'global/images/comp2023/highlights/event-4-14.jpg'
import event415 from 'global/images/comp2023/highlights/event-4-15.jpg'
import event416 from 'global/images/comp2023/highlights/event-4-16.jpg'
/*** END EVENT 4 IMAGES ***/

/*** EVENT 5 IMAGES ***/
import event51 from 'global/images/comp2023/highlights/event-5-1.jpg'
import event52 from 'global/images/comp2023/highlights/event-5-2.jpg'
import event53 from 'global/images/comp2023/highlights/event-5-3.jpg'
import event54 from 'global/images/comp2023/highlights/event-5-4.jpg'
import event55 from 'global/images/comp2023/highlights/event-5-5.jpg'
import event56 from 'global/images/comp2023/highlights/event-5-6.jpg'
import event57 from 'global/images/comp2023/highlights/event-5-7.jpg'
import event58 from 'global/images/comp2023/highlights/event-5-8.jpg'
import event59 from 'global/images/comp2023/highlights/event-5-9.jpg'
import event510 from 'global/images/comp2023/highlights/event-5-10.jpg'
import event511 from 'global/images/comp2023/highlights/event-5-11.jpg'
import event512 from 'global/images/comp2023/highlights/event-5-12.jpg'
import event513 from 'global/images/comp2023/highlights/event-5-13.jpg'
import event514 from 'global/images/comp2023/highlights/event-5-14.jpg'
import event515 from 'global/images/comp2023/highlights/event-5-15.jpg'
import event516 from 'global/images/comp2023/highlights/event-5-16.jpg'
import event517 from 'global/images/comp2023/highlights/event-5-17.jpg'
import event518 from 'global/images/comp2023/highlights/event-5-18.jpg'
// import event519 from 'global/images/comp2023/highlights/event-5-19.jpg'
// import event520 from 'global/images/comp2023/highlights/event-5-20.jpg'
// import event521 from 'global/images/comp2023/highlights/event-5-21.jpg'
// import event522 from 'global/images/comp2023/highlights/event-5-22.jpg'
// import event523 from 'global/images/comp2023/highlights/event-5-23.jpg'
// import event524 from 'global/images/comp2023/highlights/event-5-24.jpg'
// import event525 from 'global/images/comp2023/highlights/event-5-25.jpg'
// import event526 from 'global/images/comp2023/highlights/event-5-26.jpg'
/*** END EVENT 5 IMAGES ***/

/*** EVENT 6 IMAGES ***/
import event61 from 'global/images/comp2023/highlights/event-6-1.jpg'
import event62 from 'global/images/comp2023/highlights/event-6-2.jpg'
import event63 from 'global/images/comp2023/highlights/event-6-3.jpg'
import event64 from 'global/images/comp2023/highlights/event-6-4.jpg'
import event65 from 'global/images/comp2023/highlights/event-6-5.jpg'
import event66 from 'global/images/comp2023/highlights/event-6-6.jpg'
import event67 from 'global/images/comp2023/highlights/event-6-7.jpg'
import event68 from 'global/images/comp2023/highlights/event-6-8.jpg'
import event69 from 'global/images/comp2023/highlights/event-6-9.jpg'
import event610 from 'global/images/comp2023/highlights/event-6-10.jpg'
import event611 from 'global/images/comp2023/highlights/event-6-11.jpg'
import event612 from 'global/images/comp2023/highlights/event-6-12.jpg'
import event613 from 'global/images/comp2023/highlights/event-6-13.jpg'
import event614 from 'global/images/comp2023/highlights/event-6-14.jpg'
import event615 from 'global/images/comp2023/highlights/event-6-15.jpg'
/*** END EVENT 6 IMAGES ***/

const ContentGallery = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const contentData = getData(pageType)
  const [activeIndex, setActiveIndex] = useState(0);

  const groups = document.getElementsByClassName("card-group");

  function handlePrevious () {
    const nextIndex = activeIndex + 1 <= groups.length - 1 ? activeIndex + 1 : 0;
    
    const currentGroup = document.querySelector(`[data-index="${activeIndex}"]`),
          nextGroup = document.querySelector(`[data-index="${nextIndex}"]`);
          
    currentGroup.dataset.status = "after";
    
    nextGroup.dataset.status = "becoming-active-from-before";
    
    setTimeout(() => {
      nextGroup.dataset.status = "active";
      setActiveIndex(prev => nextIndex);
    });
  }

  function handleNext () {
    const nextIndex = activeIndex - 1 >= 0 ? activeIndex - 1 : groups.length - 1;
    
    const currentGroup = document.querySelector(`[data-index="${activeIndex}"]`),
          nextGroup = document.querySelector(`[data-index="${nextIndex}"]`);
    
    currentGroup.dataset.status = "before";
    
    nextGroup.dataset.status = "becoming-active-from-after";
    
    setTimeout(() => {
      nextGroup.dataset.status = "active";
      setActiveIndex(prev => nextIndex);
    });
  }

  function getData (pageType) {
    const data = [
      {
        comp: 'awaygame',
        data: [
          {
            image: event11,
            imagealt: "A bottle of Captain Morgan next to a Dallas Cowboys football on a football field"
          },
          {
            image: event12,
            imagealt: "Fans on the field of the Dallas Cowboys field during the Captain Morgan Ultimate Away Game event"
          },
          {
            image: event13,
            imagealt: "NFL football between Captain Morgan speakers"
          },
          {
            image: event14,
            imagealt: "Captain Morgan frisbee and can yard game"
          },
          {
            image: event15,
            imagealt: "Fans celebrating the field of the Dallas Cowboys field during the Captain Morgan Ultimate Away Game event"
          },
          {
            image: event16,
            imagealt: "Person wearing a shirt that says Follow the Captain with a logo of Captain Morgan"
          },
          {
            image: event17,
            imagealt: "Fans walking onto the Dallas Cowboy's field with a start in the background."
          },
          {
            image: event18,
            imagealt: "Two drinks surrounding a Captain Morgan bottle"
          },
          {
            image: event19,
            imagealt: "Three fans in front of a Captain Morgan Ultimate Away Game sign"
          },
          {
            image: event110,
            imagealt: "Fans waiting in line to get into the Ultimate Away Game"
          },
          {
            image: event111,
            imagealt: "A bottle of Captain Morgan"
          },
          {
            image: event112,
            imagealt: "People standing in front of red football lockers"
          },
          {
            image: event113,
            imagealt: "A Follow the Captain bean bag yard game"
          },
          {
            image: event114,
            imagealt: "A DJ in behind a booth that has the Captain Morgan logo in the front"
          },
          {
            image: event115,
            imagealt: "Person standing in front of a The Ultimate Away Game poster"
          },
          {
            image: event116,
            imagealt: "Fans enjoying the Ultimate Away Game on the Dallas Cowboy's field"
          },
          {
            image: event117,
            imagealt: "The entrance to the Ultimate Away Game as fans walk on to the field"
          },
        ]
      },
      {
        comp: 'capsection',
        data: [
          {
            image: event210,
            imagealt: "The Captain's Section gallery image 10"
          },
          {
            image: event21,
            imagealt: "The Captain's Section gallery image 1"
          },
          {
            image: event22,
            imagealt: "The Captain's Section gallery image 2"
          },
          {
            image: event23,
            imagealt: "The Captain's Section gallery image 3"
          },
          {
            image: event24,
            imagealt: "The Captain's Section gallery image 4"
          },
          {
            image: event25,
            imagealt: "The Captain's Section gallery image 5"
          },
          {
            image: event26,
            imagealt: "The Captain's Section gallery image 6"
          },
          {
            image: event27,
            imagealt: "The Captain's Section gallery image 7"
          },
          {
            image: event28,
            imagealt: "The Captain's Section gallery image 8"
          },
          {
            image: event29,
            imagealt: "The Captain's Section gallery image 9"
          },
          {
            image: event211,
            imagealt: "The Captain's Section gallery image 11"
          },
          {
            image: event212,
            imagealt: "The Captain's Section gallery image 12"
          },
          {
            image: event213,
            imagealt: "The Captain's Section gallery image 13"
          },
          {
            image: event214,
            imagealt: "The Captain's Section gallery image 14"
          },
        ]
      },
      {
        comp: 'houseparty',
        data: [
          {
            image: event313,
            imagealt: "Vic's House Party gallery image 13"
          },
          {
            image: event31,
            imagealt: "Vic's House Party gallery image 1"
          },
          {
            image: event32,
            imagealt: "Vic's House Party gallery image 2"
          },
          {
            image: event33,
            imagealt: "Vic's House Party gallery image 3"
          },
          {
            image: event34,
            imagealt: "Vic's House Party gallery image 4"
          },
          {
            image: event35,
            imagealt: "Vic's House Party gallery image 5"
          },
          {
            image: event36,
            imagealt: "Vic's House Party gallery image 6"
          },
          {
            image: event37,
            imagealt: "Vic's House Party gallery image 7"
          },
          {
            image: event38,
            imagealt: "Vic's House Party gallery image 8"
          },
          {
            image: event39,
            imagealt: "Vic's House Party gallery image 9"
          },
          {
            image: event310,
            imagealt: "Vic's House Party gallery image 10"
          },
          {
            image: event311,
            imagealt: "Vic's House Party gallery image 11"
          },
          {
            image: event312,
            imagealt: "Vic's House Party gallery image 12"
          },
          {
            image: event314,
            imagealt: "Vic's House Party gallery image 14"
          },
          {
            image: event315,
            imagealt: "Vic's House Party gallery image 15"
          },
          {
            image: event316,
            imagealt: "Vic's House Party gallery image 16"
          },
          {
            image: event317,
            imagealt: "Vic's House Party gallery image 17"
          },
        ]
      },
      {
        comp: 'sofi',
        data: [
          {
            image: event411,
            imagealt: "The Captain's Section - Sofi image 11"
          },
          {
            image: event41,
            imagealt: "The Captain's Section - Sofi image 1"
          },
          {
            image: event42,
            imagealt: "The Captain's Section - Sofi image 2"
          },
          {
            image: event43,
            imagealt: "The Captain's Section - Sofi image 3"
          },
          {
            image: event44,
            imagealt: "The Captain's Section - Sofi image 4"
          },
          {
            image: event45,
            imagealt: "The Captain's Section - Sofi image 5"
          },
          {
            image: event46,
            imagealt: "The Captain's Section - Sofi image 6"
          },
          {
            image: event47,
            imagealt: "The Captain's Section - Sofi image 7"
          },
          {
            image: event48,
            imagealt: "The Captain's Section - Sofi image 8"
          },
          {
            image: event49,
            imagealt: "The Captain's Section - Sofi image 9"
          },
          {
            image: event410,
            imagealt: "The Captain's Section - Sofi image 10"
          },
          {
            image: event412,
            imagealt: "The Captain's Section - Sofi image 12"
          },
          {
            image: event413,
            imagealt: "The Captain's Section - Sofi image 13"
          },
          {
            image: event414,
            imagealt: "The Captain's Section - Sofi image 14"
          },
          {
            image: event415,
            imagealt: "The Captain's Section - Sofi image 15"
          },
          {
            image: event416,
            imagealt: "The Captain's Section - Sofi image 16"
          },
        ]
      },
      {
        comp: 'battle',
        data: [
          {
            image: event52,
            imagealt: "Battle of the Captains - 2"
          },
          {
            image: event51,
            imagealt: "Battle of the Captains - 1"
          },
          {
            image: event53,
            imagealt: "Battle of the Captains - 3"
          },
          {
            image: event54,
            imagealt: "Battle of the Captains - 4"
          },
          {
            image: event55,
            imagealt: "Battle of the Captains - 5"
          },
          {
            image: event56,
            imagealt: "Battle of the Captains - 6"
          },
          {
            image: event57,
            imagealt: "Battle of the Captains - 7"
          },
          {
            image: event58,
            imagealt: "Battle of the Captains - 8"
          },
          {
            image: event49,
            imagealt: "Battle of the Captains - 9"
          },
          {
            image: event510,
            imagealt: "Battle of the Captains - 10"
          },          
          {
            image: event511,
            imagealt: "Battle of the Captains - 11"
          },
          {
            image: event512,
            imagealt: "Battle of the Captains - 12"
          },
          {
            image: event513,
            imagealt: "Battle of the Captains - 13"
          },
          {
            image: event514,
            imagealt: "Battle of the Captains - 14"
          },
          {
            image: event515,
            imagealt: "Battle of the Captains - 15"
          },
          {
            image: event516,
            imagealt: "Battle of the Captains - 16"
          },
          {
            image: event517,
            imagealt: "Battle of the Captains - 17"
          },
          {
            image: event518,
            imagealt: "Battle of the Captains - 18"
          },
          // {
          //   image: event519,
          //   imagealt: "Battle of the Captains - 19"
          // },
          // {
          //   image: event520,
          //   imagealt: "Battle of the Captains - 20"
          // },
          // {
          //   image: event521,
          //   imagealt: "Battle of the Captains - 21"
          // },
          // {
          //   image: event522,
          //   imagealt: "Battle of the Captains - 22"
          // },
          // {
          //   image: event523,
          //   imagealt: "Battle of the Captains - 23"
          // },
          // {
          //   image: event524,
          //   imagealt: "Battle of the Captains - 24"
          // },
          // {
          //   image: event525,
          //   imagealt: "Battle of the Captains - 25"
          // },
          // {
          //   image: event526,
          //   imagealt: "Battle of the Captains - 26"
          // },
        ]
      },
      {
        comp: 'siparty',
        data: [          
          {
            image: event61,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 1"
          },
          {
            image: event62,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 2"
          },
          {
            image: event63,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 3"
          },
          {
            image: event64,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 4"
          },
          {
            image: event65,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 5"
          },
          {
            image: event66,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 6"
          },
          {
            image: event67,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 7"
          },
          {
            image: event68,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 8"
          },
          {
            image: event69,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 9"
          },
          {
            image: event610,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 10"
          },
          {
            image: event611,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 11"
          },
          {
            image: event612,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 12"
          },
          {
            image: event613,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 13"
          },
          {
            image: event614,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 14"
          },
          {
            image: event615,
            imagealt: "SI THE PARTY – SUPER BOWL LVIII gallery image 15"
          },
        ]
      }
    ]

    if(pageType !== '') {
      const contentItems = data.filter(x => x.comp === pageType)[0]
      if(contentItems) {
        return contentItems.data
      }else{
        return null
      }
    }else{
      return null
    }
  }

  
  useEffect(() => {
    // const thumbs = new Swiper ('.gallery-thumbs', {
    //   slidesPerView: 'auto',
    //   spaceBetween: 10,
    //   centeredSlides: true,
    //   loop: true,
    //   slideToClickedSlide: true,
    //   modules: [Thumbs, Navigation, FreeMode],
    //   watchSlidesProgress: true,
    // });
    // const slider = new Swiper('.gallery-slider', {
    //   slidesPerView: 1,
    //   centeredSlides: true,
    //   slideToClickedSlide: true,
    //   loop: true,
    //   loopedSlides: 6,
    //   navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //   },
    //   modules: [Thumbs, Navigation, FreeMode],
    //   thumbs: {swiper: thumbs},
    //   watchSlidesProgress: true,
    // })
    // console.log("SLIDER > ", slider)
    $(function () {
      console.log("ERRR")
     
      var thumbs = new Swiper ('.gallery-thumbs', {
         slidesPerView: 'auto',
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          loop: true,
          spaceBetween: 10
      });
      var slider = new Swiper ('.gallery-slider', {
          slidesPerView: 1,
          // centeredSlides: true,
          loop: true,
          loopedSlides: 6,
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: thumbs
          }
        });
      // slider.controller.control = thumbs;
      // thumbs.controller.control = slider;
    })
  })
  return (
    <div className="gallery">
      <div className="swiper-container gallery-slider">
        <div className="swiper-wrapper">
          {contentData && contentData.length > 0 && contentData.map((item, key) => {
            return (
              <div key={`gallery_${pageType}_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                <div className="content-wrap">
                  <img src={item.image} alt={item.imagealt} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="swiper-container gallery-thumbs">
        <div className="swiper-wrapper">
          {contentData && contentData.length > 0 && contentData.map((item, key) => {
            return (
              <div key={`gallery_thumb_${pageType}_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                <div className="content-wrap">
                  <img src={item.image} alt={item.imagealt} />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="swiper-button-prev"></div>
      <div className="swiper-button-next"></div>
    </div>
  );
};

export default ContentGallery;
