import "./content-form.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";
import {Helmet} from 'react-helmet'
import moment from 'moment'
import {load} from "recaptcha-v3";

import {generateKey} from "global/utils/utils";

const ContentWSForm = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const [date, setDate] = useState("");
  /* pre filling props that should be there for the image upload */
  let errorMsg = ''
  // let labelStyle = { ProjectLabelStyle }
  // let setValue = { setValue }
  let isFocusable = false
  let id = 'fileUpload'
  let registeredId = 'fileUpload'
  let subText = 'PNG, GIF, JPG, or JPEG only. Max size: 10MB'
  let maxLength = 10485760
  let accept = '.png, .gif, .jpg, .jpeg,'

  const [file, setFile] = useState(null)
  const [uploadingStatus, setUploadingStatus] = useState(false)
  const [fileUrl, setFileUrl] = useState('')

  function checkImage() {
    const fileError = document.getElementById("fileError")
    let errorState = false
    $("#fileUpload").removeClass("error")
    fileError.classList.add("hide")
    fileError.classList.remove("show")
    if ($("#fileUpload")[0].files[0].length == 0) {
      // jQuery("#formImage").closest('.input-item').addClass('has-error');
      // $("#img_fileName").html('No file selected.');
      // hasError = true;
    } else {
      var imageType = $("#fileUpload")[0].files[0].type.split("/")
      var imgName = $("#fileUpload")[0].files[0].name.replace(/(<([^>]+)>)/gi, "")

      if (
        imageType[1].toLowerCase() == "png" ||
        imageType[1].toLowerCase() == "jpeg" ||
        imageType[1].toLowerCase() == "jpg"
      ) {
        if ($("#fileUpload")[0].files[0].size < 10000000) {
          // do stuff here?
        } else if ($("#fileUpload")[0].files[0].size > 10000000) {
          $("#fileUpload").addClass("error")
          //$('#img_fileName').html('File size exceeds 10MB');
          fileError.classList.remove("hide")
          $("#file-error").html("File size exceeds 10MB")
          fileError.classList.add("show")
          errorState = true
        }
      } else {
        errorState = true
        $("#fileUpload").addClass("error")
        $("#fileError").html("Invalid file. Please upload only JPG or PNG.")
        fileError.classList.remove("hide")
        fileError.classList.add("show")
      }
    }

    return errorState
  }

  function handleNumChange (e) {
    let key = e.key
    if(key.toLowerCase() !== 'backspace' && key.toLowerCase() !== 'tab'){
      if (isNaN(key)) {
        e.preventDefault()
      }
    }else{
      return true
    }
  }

  function handleStoreChange (e) {
    const storeVal = $("#PR_4_90_1_freetext").find(":selected").val()

    if(storeVal === 'other') {
      $('#storeField').removeClass('hide')
      $('#storeOtherField').prop('required', true)
    }else{
      $('#storeField').addClass('hide')
      $('#storeOtherField').prop('required', false)
    }
  }

  function handleWsChange (e) {
    const storeVal = $("#PR_4_502_1_freetext").find(":selected").val()

    if(storeVal === 'other') {
      $('#wsField').removeClass('hide')
      $('#wsOtherField').prop('required', true)
    }else{
      $('#wsField').addClass('hide')
      $('#wsOtherField').prop('required', false)
    }
  }
  function checkAge (dob) {

    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);
    if(dob.indexOf('-') === 4){
      if (moment().diff(birthday, "years", false) < 18) {
        return true
      } else {
        return false
      }
    }else{
      console.log('THIS SHOULD SEND AN ERROR')
      return true
    }
  }
 
  // eslint-disable-next-line complexity
  async function formSubmit(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#cidbSubmit').prop('disabled', true).addClass('disabled')
    $('#errorMessage').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });

    event.preventDefault()

    const fname = $("#CP_FirstName").val();
    const lname = $("#CP_LastName").val();
    const email = $("#CP_EmailId").val();
    const phone = $("#CP_PhoneNumber").val();
    const store = $("#PR_4_90_1_freetext").find(":selected").val();    
    const storeOther = $('#storeOtherField').val()
    const wholesaler = $("#PR_4_502_1_freetext").find(":selected").val()
    const wholesalerOther = $('#wsOtherField').val()
    const tc = $("#tc").prop("checked");
    const checkboxFields = $("#PR_1_64_1").prop("checked");
    const imageFile = $("#PR_4_416_1_freetext").val();
    let captcha = "";

    // format dob 
    const dobArr = $('#CP_DOB').val().split('/')
    const newDob = dobArr[1] + '/' + dobArr[0] + '/' + dobArr[2]
    const dob =  moment(newDob).format("YYYY-MM-DD")
    // console.log("form submit >>> ", fname);
    // console.log("form submit >>> PR_4_466_1_freetext ", PR_4_466_1_freetext);

    //check required fields are present
    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        || phone.trim() === ""
        || store === ""
        || wholesaler === ""
        || imageFile === ""
        || dob.trim() === "" 
        || tc === false) {

      if (fname.trim() === "") {
        $("#CP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#CP_LastName").addClass("error");
      }
      if (email.trim() === "") {
        $("#CP_EmailId").addClass("error");
      }
      if (phone.trim() === "") {
        $("#CP_PhoneNumber").addClass("error");
      }
      if (dob.trim() === "") {
        $("#CP_DOB").addClass("error");
      }
      if (store === "") {
        $("#PR_4_90_1_freetext").addClass("error");
      }
      if (wholesaler === "") {
        $("#PR_4_502_1_freetext").addClass("error");
      }
      if (imageFile === "") {
        $("#fileUpload").addClass("error");
      }
      formError = true
    }
    // check store value
    if(store === "other") {
      if(storeOther.trim() === "") {
        $('#storeOtherField').addClass("error")
        formError = true
      }
    }
    // check store value
    if(wholesaler === "other") {
      if(wholesalerOther.trim() === "") {
        $('#wsOtherField').addClass("error")
        formError = true
      }
    }
    //check age
    ageError = checkAge(dob)
    // console.log('formError >>>> ', formError)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('form__hw2023cs'));
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
            // eslint-disable-next-line radix
            send[key] = parseInt(value);
        } else if (key === "PR_4_92_1" || key === "tc" || key === "PR_1_64_1") {
          if (value === "on") {
              send[key] = "1";
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "CP_DOB") {
          send[key] = dob
        } else if (key === "PR_4_90_1_freetext") {
          if(value === 'other') {
            send[key] = storeOther.trim()
          }else{
            send[key] = value
          }
        } else if (key === "PR_4_502_1_freetext") {
          if(value === 'other') {
            send[key] = wholesalerOther.trim()
          }else{
            send[key] = value
          }
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else {
          send[key] = value;
        }
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const res = await fetch(`/api/cidbcomp/add`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());

          // console.log('RES >>>> ', res)
          if(res && res.success) {
            window.location.href = "/en-gb/ws-onehellofacocktail/thanks/"
          }else{
            let message = 'Something went wrong. Please try again.'

            if(res.message) {
              message = res.message
            }
            $('#errorMessage').html(`<div>${message}</div>`)
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          if($('#cidbSubmit')) {
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        // console.log('form errors')
        let errMsg = "";

        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || phone.trim() === ""
          || store.trim() === ""
          || imageFile.trim() === ""
          || wholesaler.trim() === ""
          || dob.trim() === "") {
          if(imageFile.trim() === ""){
            errMsg = "<div>Please upload a photo.</div>";
          }else{
            errMsg = "<div>A required field is missing.</div>";
          }
        }

        if(ageError) {
          errMsg = "<div>You must be 18 and over to enter.</div>";
        }

        if (tc === false) {
          errMsg += "<div>Please accept the terms and conditions.</div>";
        }
        if (captcha === "") {
          errMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
        $('#errorMessage').html(errMsg)
        errorMessage = errMsg
        formError = false
      }
    })
  }

  const handleDateChange = (e) => {
    let value = e.target.value;
    let backsapce = date.length > value.length;

    if (!backsapce && (date.length == 1 || date.length == 4)) {
      value += "/";
    }
    setDate(value);
  };

  async function uploadFile () {
    setUploadingStatus(true)
    const fileName = generateKey(10) + '_' + file.name.replace(/ /g, '_') // replace all spaces in file name. S3 doesn't do well with them    
    let send = {};

    const fileHasError = checkImage()
    if(!fileHasError) {
      window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
        send['gRecaptchaToken'] = token
        const response = await fetch(`/api/upload/add?file=${fileName}&fileType=${file.type}&size=${file.size}`, {
          body: JSON.stringify(send),
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",

          },
          method: "POST",
        // eslint-disable-next-line no-shadow
        }).then((res) => res.json())
        .catch(() => {
          throw new Error('Error fetching for presigned urls')
        })
        // console.log('RESPONSE >>> ', response)
        if (response && response.success && response.success === true) {
          const url = response.data.url
          const fields = response.data.fields
          if (!url) {
            throw new Error('No URL found.')
          } else {
            const uploadFormData = new FormData()
            Object.entries({ ...fields, file: file }).forEach(([key, value]) =>
              uploadFormData.append(key, value),
            )
            // This needs to be passed to the DB
            const publicUrl = 'https://' + fields.bucket + '.s3.amazonaws.com/' + fields.key
            await fetch(url, {
              mode: 'no-cors',
              method: 'POST',
              body: uploadFormData,
            })
            setFileUrl(publicUrl)
            setUploadingStatus(false)
            setFile(null)
          }
        } else {
          console.log('ERROR : ', response.error)
        }
      }) // END RECAPTCHA EXECUTE
    }
  }

  useEffect(() => {
    if (file) {
      const uploadedFileDetail = async () => await uploadFile()
      uploadedFileDetail()
    }
  }, [file])

  const [focus, setFocus] = useState(false)
  const [input, setInput] = useState('')
  
  useEffect(() => {
    $(function () {
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $("input.input, select.input").on("focusin", function () {       
        $(this).siblings(".inputlabel").removeClass("inputlabel--empty")
      });

      $("input.input, select.input").on("focusout change", function () {
        if ($(this).val() === "") {
          $(this).siblings(".inputlabel").addClass("inputlabel--empty");
        }
      });
    })
  })
  return (
    <div className="hw2023content__form form">
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
      </Helmet>
      <div className="container container--1200">
        <div className="form__wrap">
          <form id="form__hw2023cs" runat="server" action="/api/CIDBapi_Form">
            <input id="PR_4_367_1_freetext" name="PR_4_367_1_freetext" type="hidden" placeholder="" runat="server"value="" />
            <input id="HD_PromoCode" name="HD_PromoCode" runat="server" type="hidden" value={'DWE028CPT0823AA0022'} />

            <input id="HD_Consumerid" name="HD_Consumerid" runat="server" type="hidden" value="" />

            <div className="inputWrapper labeled half">
              <label htmlFor="CP_FirstName" className="inputlabel inputlabel--empty">FIRST NAME *</label>
              <input id="CP_FirstName" name="CP_FirstName" type="text" className="input" required />
            </div>

            <div className="inputWrapper labeled half">
              <label htmlFor="CP_LastName" className="inputlabel inputlabel--empty">LAST NAME *</label>
              <input id="CP_LastName" name="CP_LastName" type="text" className="input" required />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_EmailId" className="inputlabel inputlabel--empty">EMAIL*</label>
              <input id="CP_EmailId" name="CP_EmailId" type="email" className="input" required />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_PhoneNumber" className="inputlabel inputlabel--empty">MOBILE NUMBER *</label>
              <input
                type="text"
                name="CP_PhoneNumber"
                id="CP_PhoneNumber"
                className="input"
                maxLength={11}
                onKeyUp={e => handleNumChange(e)}
                onKeyDown={e => handleNumChange(e)}
                />
                <input
                id="HD_PhoneType"
                name="HD_PhoneType"
                type="hidden"
                value="3"
                />
            </div>

            <div className="inputWrapper labeled">
              <label htmlFor="CP_DOB" className="inputlabel inputlabel--empty">DATE OF BIRTH *</label>
              <input 
                id="CP_DOB"
                name="CP_DOB"
                type="text"
                value={date}
                maxLength={10}
                onChange={e=> handleDateChange(e)}
                placeholder="dd/mm/yyyy"
                className="input"
                required />
            </div>
            <div className="inputWrapper labeled select">
              <label htmlFor="PR_4_90_1_freetext" className="inputlabel inputlabel--empty">Name of your Convenience Store *</label>
              <select className="input" id="PR_4_90_1_freetext" name="PR_4_90_1_freetext" defaultValue="" onChange={(e) => handleStoreChange(e)} required>
                <option value="" disabled></option>
                <option value="Londis">Londis</option>
                <option value="Budgens">Budgens</option>
                <option value="Nisa">Nisa</option>
                <option value="Costcutter">Costcutter</option>
                <option value="Premier">Premier</option>
                <option value="Best One">Best One</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="inputWrapper labeled hide" id="storeField">
              <label htmlFor="storeOtherField" className="inputlabel inputlabel--empty">Name of Convenience Store *</label>
              <input id="storeOtherField" name="storeOtherField" type="text" className="input" />
            </div>
            <div className="inputWrapper labeled select">
              <label htmlFor="PR_4_502_1_freetext" className="inputlabel inputlabel--empty">Which wholesaler did you purchase from *</label>
              <select className="input" id="PR_4_502_1_freetext" name="PR_4_502_1_freetext" defaultValue="" onChange={(e) => handleWsChange(e)} required>
                <option value="" disabled></option>
                <option value="Bestway">Bestway</option>
                <option value="Dhamecha">Dhamecha</option>
                <option value="Booker">Booker</option>
                <option value="Parfetts">Parfetts</option>
                <option value="HT Drinks">HT Drinks</option>
                <option value="United Wholesale">United Wholesale</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="inputWrapper labeled hide" id="wsField">
              <label htmlFor="wsOtherField" className="inputlabel inputlabel--empty">Name of Wholesaler *</label>
              <input id="wsOtherField" name="wsOtherField" type="text" className="input" />
            </div>
            <div className="inputWrapper checkbox ptop">
              <div className="form__text form__text--large">
                Upload a photo of your itemised till receipt *
              </div>
            </div>

            <div className="inputWrapper checkbox">
              <div className="form__text">
                Your photo must include the receipt/invoice date and show your purchase. Receipts/ invoices must be dated between 01.10.23 and 01.11.23.
              </div>
            </div>

            <div className="inputWrapper labeled fileUpload">
              <label
                htmlFor={id}
                className={`inputlabel inputlabel--empty`}
                tabIndex={isFocusable ? 0 : -1}
              >
                Upload File *                      
              </label>
              <input
                type="file"
                accept={accept}
                maxLength={maxLength}
                id={id}
                aria-describedby={`${id}Error`}
                className="input"
                onFocus={() => {
                  setFocus(true)
                }}
                onBlurCapture={() => {
                  setFocus(false)
                }}
                // {...register}
                onChange={(e) => {
                  setInput(e.target.value)
                  setFile(e.target.files?.[0])
                  // setValue(registeredId, e.target.value)
                }}
              />
              <input type="hidden" id="PR_4_416_1_freetext" name="PR_4_416_1_freetext" value={fileUrl} />
            </div>
            <div className="inputWrapper checkbox">
              <div className="form__text form__text--small">                
                <div className="right relative basis-1/2 translate-y-1/2">
                  <p className="text-white">{subText}</p>
                  {input && <p className="text-white">File: {input.slice(12)}</p>}
                </div>
                {
                  <span id={`${id}Error`} role="alert" className="w-full text-center text-sm text-red-600">
                    {errorMsg}
                  </span>
                }
              </div>
            </div>

            <div className="inputWrapper checkbox">
              <div className="form__text form__text--red" id="fileError">
              </div>
            </div>

            {/*<div className="inputWrapper checkbox">
              <div className="form__text form__text--small">
                <a href="#" target="_blank">See our upload guidelines</a>
              </div>
              </div>*/}            

            <div className="inputWrapper checkbox ptop">
              <input id="tc" name="tc" type="checkbox" required />
              <label htmlFor="tc" dangerouslySetInnerHTML={{
                __html: `I confirm that I am over 18 years old and agree to the promotional <a href="/en-gb/onehellofacocktail/terms" target="_blank">terms and conditions</a> *`
                }}
              />
            </div>
            
            <div className="inputWrapper checkbox">
              <input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" required />
              <label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                __html: `Tick here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.`
                }}
              />
            </div>
            <div className="inputWrapper checkbox">
              <div className="form__text">
                * Mandatory Fields
              </div>
            </div>
              <div className="inputWrapper">
                  <div id="recaptcha"></div>
              </div>

              <div className="inputWrapper">
                  <div className="errorMessage" id="errorMessage"></div>
              </div>

            <div className="inputWrapper">
              <input id="compname" name="compname" runat="server" type="hidden" value={compName} />
              <input id="HD_IsDefaultFlag" name="HD_IsDefaultFlag" runat="server" type="hidden" value="1" />
              <input id="HD_PR_1_64_1_Brand" name="HD_PR_1_64_1_Brand" runat="server" type="hidden" value="4" />
              <input id="HD_PR_1_64_1_Comm" name="HD_PR_1_64_1_Comm" runat="server" type="hidden" value="1" />
              <input id="HD_AddressType" name="HD_AddressType" runat="server" type="hidden" value="1" />
              <input id="CP_WebsiteUrl" name="CP_WebsiteUrl" runat="server" type="hidden" value="https://www.captainmorgan.com/" />

              <input className="submit btn btn--bgredg btn--textwhite" type="submit" value="ENTER" id='cidbSubmit' onClick={(evnt) => formSubmit(evnt)} />
            </div>
            <div className="inputWrapper copy disclaimer">
              <small dangerouslySetInnerHTML={{
              __html: `Open to registered GB independent convenience store owners only, 18+. Internet access and purchase of any 12 bottles or 2 cases of Captain Morgan Tiki, Captain Morgan Original Spiced Gold, Captain Morgan White Rum, Captain Morgan Dark Rum, Captain Morgan Sliced Apple, Captain Morgan Dark Spiced or Captain Morgan 0.0% required in 1 transaction for entry into Prize Draw. 1 prize draw, for a chance to win 1 x £6,666 cash prize. Receipt/invoice upload required. Scan QR code or visit <a href="https://www.captainmorgan.com/en-gb/ws-onehellofacocktail">https://www.captainmorgan.com/en-gb/ws-onehellofacocktail</a> to enter. Opens 00:00 01.10.23. Closes 23:59 01.11.23. Limited to 1 entry per valid mobile number per week. Max 1 prize per convenience store. For full terms and conditions visit: <a href="https://www.captainmorgan.com/en-gb/ws-onehellofacocktail">https://www.captainmorgan.com/en-gb/ws-onehellofacocktail</a>. It is recommended that you take a photo/copy of the full Terms & Conditions for your reference. Promoter: Diageo, W1F 7HS.`
              }}
            />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentWSForm;
