/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import buildPage from "../page-builder";

import heroImage from 'global/images/comp2023/att_stadium_rsvp.jpg'
import blackBg from 'global/images/comp2023/black-background.jpg'
import redBg from 'global/images/comp2023/red_bg_static.jpg'
import footerLogo from 'global/images/comp2023/footer_logo.png'

import NavCustom from "../../organisms/nav-custom";
import RsvpComp from "../../organisms/RsvpComp";

import footerFBLogo from 'global/images/comp2023/facebook.png'
import footerIGLogo from 'global/images/comp2023/IG.png'
import footerTWLogo from 'global/images/comp2023/TW.png'
import footerYTLogo from 'global/images/comp2023/YT.png'
import animatedTagline from 'global/images/comp2023/animated_tagline.gif'

export default class PageContainer extends Component {

  state = {
    "isLoaded": false,
    "setRoutes": false,
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }
  async checkURLparams () {
    const wndw = window
    const params = new URLSearchParams(wndw.location.search)

    
    if (params.has("validatekey")) {
      const userKey = params.get('validatekey')
      const validateKey = 'WVZaYVJXUkZkelJWYWtwdVZqTmtNVTFIU1RKT1NGRXdaVzVHUWxreVVrNVdTRmw2WWxSbmVVMHpaejA9'

      if (userKey === validateKey) {

      }else{
        wndw.location.href = "/followthecaptain/"
      }
    } else {
      wndw.location.href = "/followthecaptain/"
    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }

  componentDidMount () {
    window.addEventListener("load", this.checkURLparams());
  }

  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const heroBg = this.props && this.props.herobg ? this.props.herobg : heroImage
    const compName = this.props && this.props.compname ? this.props.compname : ''

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }
  const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          url: '/en-us',
        },
        {
          title: 'Clue',
          url: '/followthecaptain#clue',
        },
        {
          title: 'Prize',
          url: '/followthecaptain#prize',
        },
        {
          title: 'Events',
          url: '/followthecaptain#events',
        },
      ]
    }

    return (
      <div className="page rsvpcomp">
        <Helmet>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure.Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <title>Follow the Captain | Captain Morgan</title>
          <script src="https://www.google.com/recaptcha/api.js?render=6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T"></script>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content" id="main" role="main">
          <section className="comp2__section rsvp rsvp__hero" id="rsvp" style={{backgroundImage: `url(${heroBg})`}}>
            <div className="rsvp__wrap flex">
              <div className="container container--1440">
                <h1 className="gold__font">
                  <span>follow the captain to</span>
                  THE CAPTAIN'S SECTION
                </h1>
                <div className="rsvp__copy">
                  Ever wanted to sit VIP at your favorite NFL teams game?<br/><br/>
                  <b>You could score a ticket to the Arizona Cardinals game on their home turf on October 29th, and watch all the action from our exclusive Captain Morgan section.</b><br/><br/>
                  And you can ONLY find the tickets here.<br /><br />
                  Follow the Captain for the adventure.
                </div>
              </div>
            </div>
          </section>
          <RsvpComp 
            location={this.props.location}
            compName={compName}
            listid='UBT4W5'
            returnlink='/followthecaptain'
            entrySource='captains_corner_rsvp'
            formbodybg='redseats'
          />
          <section className="comp2__section rsvpfooter footer" style={{backgroundImage: `url(${redBg})`}}>
            <div className="footer__reg reg">
              <label className="reg__label" htmlFor="rsvpInfo">
                Keep me informed:
              </label>
              <a href="/en-us/newsletter/?source=followthecaptain" className="btn btn--decorated btn--bgwhite btn--textred" id="rsvpInfo">
                Sign up
              </a>
            </div>
            <div className="footer__logo">
              <img src={animatedTagline} alt="Captain Morgan Logo" />
            </div>
            <footer className="page__footer" role="contentinfo">
             <aside className="follow-us">
              <div className="follow-us__inner">
                  <h2 className="follow-us__title">Follow Captain Morgan</h2>
                  <div className="follow-us__strapline">Be the first to know about events, promotions and competitions</div>
                  <ul className="follow-us__options">
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganUSA/?brand_redir=138940200780" target="_blank" rel="noopener noreferrer">
                             <div className="styled-icon">
                            <img src={footerFBLogo} aria-hidden />
                            </div>
                          <span className="follow-us__option-link-alt">Facebook</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.instagram.com/captainmorganusa/" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">                         
                              <img src={footerIGLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">Instagram</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://twitter.com/CaptainMorganUS" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                              
                                <img src={footerTWLogo} aria-hidden/>
                              
                          </div>
                          <span className="follow-us__option-link-alt">Twitter</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.youtube.com/user/CaptainMorgan" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                                <img src={footerYTLogo} aria-hidden/>
                          </div>
                          <span className="follow-us__option-link-alt">YouTube</span>
                        </a>
                    </li>
                    
                  </ul>
              </div>
            </aside>

               <div className="footer__disclaimer">
                <div className="footer__rules">
                        <a  href="/captain-morgan-nfl-rules.pdf">Official Rules</a>
                    </div>
                <div className="copy">
                  <strong>
                    CAPTAIN MORGAN ORIGINAL SPICED RUM. Caribbean Rum Made with Vanilla, Spice, and Other Natural Flavors. 35% Alc./Vol. Captain Morgan Rum Co., New York, NY. Please Drink Responsibly. Do not forward to anyone under the age of 21.
                  </strong>
                </div>
              </div>
            </footer>
          </section>
        </main>
      </div>
    );
  }
}
