/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import $ from "jquery";
import React from "react";
import fetch from "isomorphic-fetch";
import {load} from "recaptcha-v3";

import moment from "moment";
import * as routeList from "global/utils/rsvpcontent";

const cn = require("classnames");

class RsvpComp extends React.Component {

    constructor (props) {
      super(props);
      this.findLink = "";
      this.useProps = routeList.getSectionAssets(this.props.location.pathname)
      this.state = {
          dobErr: false,
          errorMessage: "",
          formError: false,
          fullTitle: this.useProps && this.useProps.rsvp && this.useProps.rsvp.fulltitle ? this.useProps.rsvp.fulltitle : 'RSVP FULL',
          fullCopy: this.useProps && this.useProps.rsvp && this.useProps.rsvp.fullcopy ? this.useProps.rsvp.fullcopy : 'RSVP FULL',
          thankYouTitle: this.useProps && this.useProps.rsvp && this.useProps.rsvp.thankyoutitle ? this.useProps.rsvp.thankyoutitle : 'Thank you!',
          thankYouCopy: this.useProps && this.useProps.rsvp && this.useProps.rsvp.thankyoucopy ? this.useProps.rsvp.thankyoucopy : 'Event details will be emailed to you!',
      };
    }

    updateErrorMessage (message) {

      const errorDiv = document.getElementById("errorMessage");

      errorDiv.innerHTML = message;
      this.state.errorMessage = message;
    }

    checkAge (dob) {

      /* [SEARCH-MINIMUM-AGE] */
      const birthday = moment(dob);

      if (moment().diff(birthday, "years", false) < 25 || moment().diff(birthday, "years", false) > 125) {
          this.state.dobErr = true;
          $("#CP_DOB").addClass("error");
          $("#dob__error").addClass("showError");
          this.state.formError = true;
      } else {
          this.state.dobErr = false;
      }
    }

    fetchPPVersionOnLoad () {
      try {
        const ppVer = window.dg_footer_configurations.hg_PP_Version;

        if (ppVer) {
          return ppVer
        } else {
          throw new Error("Cannot get Privacy Policy version");
          return 0
        }

      } catch (err) {
          console.warn(err);
      }
    }
    // eslint-disable-next-line complexity
    async registerUser (evnt) {
        $('#cidbSubmit').prop('disabled', true).addClass('disabled')
        // eslint-disable-next-line func-names
        $(".error").each(function () {
            // eslint-disable-next-line babel/no-invalid-this
            $(this).removeClass("error");
        });

        $("#dob__error").removeClass("showError");
        evnt.preventDefault();

        console.log("form submit >>> ", $("#CP_FirstName").val());
        const fname = $("#CP_FirstName").val();
        const lname = $("#CP_LastName").val();
        const email = $("#CP_EmailId").val();
        const country = $("#PR_4_92_1").val();
        const team = $("#team").val();
        const dob = $("#CP_DOB").val();
        const zip = $("#PR_4_324_1_freetext").val();
        const tc = $("#tc").prop("checked");
        const checkboxFields = $("#PR_1_64_1").prop("checked");
        const theBarFields = $("#PR_1_64_2").prop("checked");

        let captcha = "";

        this.updateErrorMessage("");
        this.state.formError = false;
        const data = new FormData(document.getElementById("formEnterCode"));
        // Console.log('IN REGISTER USER >>>> data ', data)

        if (!checkboxFields) {
          data.append("PR_1_64_1", false);
        }else{
          data.append("PR_1_64_1", true);
        }
        if (!theBarFields) {
          data.append("PR_1_64_2", false);
        }else{
          data.append("PR_1_64_2", true);
        }
        //Converts formdata to json
        const send = {};

        data.forEach((value, key) => {

          if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
              // eslint-disable-next-line radix
              send[key] = parseInt(value);
          } else if (key === "PR_4_92_1" || key === "tc") {
            if (value === "on") {
                send[key] = "1";
            }
          } else if (key === "g-recaptcha-response") {
            send['captcha'] = value
            send[key] = value
          } else if (key === "tc") {
            send[key] = value === 1 ? true : false
          } else if (key === "plusone") {
            send[key] = value === "true" || value === true ? true : false
          } else {
            send[key] = value;
          }

        });
        send['ppVersion'] = this.fetchPPVersionOnLoad()
        send.CP_WebsiteUrl = window.location.href;
        const json = JSON.stringify(send);

        captcha = send["g-recaptcha-response"];
        console.log("RSVP FORM SUBMIT >>>> captcha ", captcha)
        if (team.trim() === "" || fname.trim() === "" || lname.trim() === "" || email.trim() === "" || country === null || dob.trim() === "" || tc === false || captcha === "" || zip.trim()==="") {

          if (fname.trim() === "") {
            $("#CP_FirstName").addClass("error");
          }

          if (lname.trim() === "") {
            $("#CP_LastName").addClass("error");
          }

          if (email.trim() === "") {
            $("#CP_EmailId").addClass("error");
          }

          if(team.trim() === "") {
            $("#team").addClass("error");
          }

          if (country === null) {
            $("#PR_4_92_1").addClass("error");
          }

          if (dob.trim() === "") {
            $("#CP_DOB").addClass("error");
          }
           if (zip.trim() === "") {
            $("#PR_4_324_1_freetext").addClass("error");
          }
          
          this.state.formError = true;
        }
        console.log("IN REGISTER USER >>>> send ", send);

        this.checkAge(dob);
        //Do post request to api here
        // eslint-disable-next-line no-negated-condition
        if (!this.state.formError) {
          try {
            const res = await fetch("/api/rsvpcomp/add", {
                body: json,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",

                },
                method: "POST",
            // eslint-disable-next-line no-shadow
            }).then((res) => res.json());

            if (res.success) {

              console.log("SUCCESS Add ANS", res);
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')

              // In this case, just delete the form and show the success message
              $("#formEnterCode, #leftPanel").remove();$("#formWrapper").append(`<div id="thankyou"><h1 class="lead__title">${this.state.thankYouTitle}</h1><div class="thankyou__copy">${this.state.thankYouCopy}</div></div>`);

              console.log('window locatioin href >>> ', window.location.href)
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
              });
                window.dataLayer.push({
                "event": "customEvent",
                "eventCategory": "Opt-in",
                "eventAction": "Competition",
                "eventLabel": "success_true",
                "eventInteraction": "true",
              })
            } else {
              const {message, errorType} = res;

              console.log("ERROR SUCCESS ANS", res);
              console.log("ERROR SUCCESS ANS", message);
              console.log("ERROR SUCCESS ANS", errorType);
              if(errorType === 'full') {
                // In this case, just delete the form and show the success message
                $("#formEnterCode, #leftPanel").remove();

                $("#formWrapper").append(`<div id="full"><h1 class="lead__title">${this.state.fullTitle}</h1><div class="thankyou__copy">${this.state.fullCopy}</div></div>`);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                'event':'pageView',
                'pagePath': window.location.href +'/thank-you'
                });
                  window.dataLayer.push({
                  "event": "customEvent",
                  "eventCategory": "Opt-in",
                  "eventAction": "Competition",
                  "eventLabel": "success_false",
                  "eventInteraction": "true",
                })
              }
              this.updateErrorMessage(`<div>${message}</div>`);
              this.state.formError = true;
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
              // Return res
            }
          } catch (error) {
            console.log("ERROR ERROR ANS", error);
            if($('#cidbSubmit')) {
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
            }
            return 0;
          }

        } else {

          let errorMsg = "";
          // console.log('RQ FIELD >> fname ', fname)
          // console.log('RQ FIELD >> lname ', lname)
          // console.log('RQ FIELD >> email ', email)
          // console.log('RQ FIELD >> country ', country)
          // console.log('RQ FIELD >> dob ', dob)
          if (team === "" || fname === "" || lname === "" || email === "" || country === "" || dob === "" || zip === "") {
            errorMsg = "<div>A required field is missing.</div>";
          }

          if (tc === false) {
            errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
          }

          if (captcha === "") {
            errorMsg += "<div>Please answer the Captcha.</div>";
          }
          $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          this.updateErrorMessage(errorMsg);
        }
    }

    captchaLoad = () => {
      load("6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt", {
          explicitRenderParameters: {
              container: "recaptcha",
          },
      });
    }

    async checkVenue () {
      const venue = this.props && this.props.compName ? this.props.compName : ''
      console.log('VENUD > ', venue)
      if(venue !== '') {
        try {
          const json = JSON.stringify({compname: venue});
          const res = await fetch("/api/rsvpcomp/status", {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());
          console.log("CHECK VENUE RES > ", res)

          if (res && res.success && res.success === true) {

          }else{
            // In this case, just delete the form and show the success message
            $("#formEnterCode, #leftPanel").remove();

            $("#formWrapper").append(`<div id="full"><h1 class="lead__title">${this.state.fullTitle}</h1><div class="thankyou__copy">${this.state.fullCopy}</div></div>`);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event':'pageView',
            'pagePath': window.location.href +'/thank-you'
            });
              window.dataLayer.push({
              "event": "customEvent",
              "eventCategory": "Opt-in",
              "eventAction": "Competition",
              "eventLabel": "success_false",
              "eventInteraction": "true",
            })
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          $("#formEnterCode, #leftPanel").remove();

          $("#formWrapper").append(`<div id="full"><h1 class="lead__title">Error.</h1><div class="thankyou__copy">Page Not Found.</div></div>`);
          return 0;
        }
      }else{
        $("#formEnterCode, #leftPanel").remove();

        $("#formWrapper").append(`<div id="full"><h1 class="lead__title">Error.</h1><div class="thankyou__copy">Page Not Found.</div></div>`);
      }
    }

    componentDidMount () {
      //JS goes here
      window.addEventListener("load", this.captchaLoad());
      window.addEventListener("load", this.checkVenue());
      $(function () {
      
        // need to remove newsletter modal, it's conflicting with the form
        if($('#newsletterModal')) {
          $("#newsletterModal").remove()
        }
        $('#PR_4_466_1_freetext').val('')

        $("input.input, select.input").on("focusin", function () {
         
          $(this).siblings(".inputlabel").removeClass("inputlabel--empty");
        });

        $("input.input, select.input").on("focusout change", function () {
          if ($(this).val() === "") {
            $(this).siblings(".inputlabel").addClass("inputlabel--empty");
          }
        });
      })
      
    }

    render () {
      const formAssets = routeList.getSectionAssets(this.props.location.pathname)
      // console.log('IN RENDER RSVPFORM >>> ', formAssets)
      const formCopy = formAssets && formAssets.rsvp && formAssets.rsvp.formcopy ? formAssets.rsvp.formcopy : 'RSVP'
      const formTitle = formAssets && formAssets.rsvp && formAssets.rsvp.formtitle ? formAssets.rsvp.formtitle : 'EVENT RSVP'
      const formTermsCopy = formAssets && formAssets.rsvp && formAssets.rsvp.formterms ? formAssets.rsvp.formterms : "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy and Cookie Notice</a>. *"
      const formTheBarCopy = formAssets && formAssets.rsvp && formAssets.rsvp.thebarcopy ? formAssets.rsvp.thebarcopy : 'Yes, I would like to receive promotions and special offers for gifting spirits, cocktail kits and barware from TheBar.com.'
      const formNewsCopy = formAssets && formAssets.rsvp && formAssets.rsvp.newslettercopy ? formAssets.rsvp.newslettercopy : 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time'
      
      const promoCode = this.props && this.props.listid ? this.props.listid : ''
      const locationVal = this.props && this.props.compName ? this.props.compName : ''
      const entrysource = this.props && this.props.entrySource ? this.props.entrySource : ''
      const vipcomp = this.props && this.props.vipComp ? this.props.vipComp : false
      const formBodyBg = this.props && this.props.formbodybg ? this.props.formbodybg : ''
      console.log('this.props >>> ', this.props)
      const formDisclaimer = formAssets && formAssets.rsvp && formAssets.rsvp.formdisclaimer ? formAssets.rsvp.formdisclaimer : '<strong>notice</strong><br /><br />this event is being photographed<br /><br />By attending this event you (1) agree to be photographed and (2) consent to the use of your name, image and/or likeness in photographs or video from this event for publicity purposes including, without limitation, on the internet and in digital media.<br /><br />By attending this event you (3) release diageo americas inc., its affiliates, parents, subsidiaries, employees, contractors, agents, representatives, transferees, assigns and licensees ("diageo") from, and waive, any and all claims arising from, related to or in connection with the use of your name, image and/or likeness in the photographs from this event and (4) agree to receive no compensation for any rights here granted by you, and (5) confirm that you have the right to grant the above consents and waivers.'
      const returnLink = this.props && this.props.returnlink ? this.props.returnlink : '/en-us'
      return (
      	<>
      		<section className={cn("rsvpFormComp", formBodyBg !== '' ? `rsvpFormComp--${formBodyBg}` : '')}>
            <div className="container container--1440">
      		    <div className="leftPanel" id="leftPanel">
    		        <div className="inner">
                  <div className="partner">
                    <div className="partner__block intro image">
                      <div className="intro__date gold__font">
                        {locationVal === 'nflrsvp20231' && 
                          `10.08.23`
                        }
                        {locationVal === 'nflrsvp20232' && 
                          `Enter your information below to reserve your spot at the game`
                        }
                        {locationVal === 'nflrsvp20233' && 
                          `Enter your information below to reserve your spot.`
                        }
                        {locationVal === 'nflrsvp202332' && 
                          `Enter your information below to reserve your spot.`
                        }
                        {(locationVal === 'nflrsvp202341' || locationVal === 'nflrsvp202342') &&
                            `Enter your information for a chance to win`
                          }
                        {locationVal === 'nflrsvp202351' &&
                          `Enter your information for a chance to win`
                        }
                      </div>
                      <div className="intro__location">
                        <span className={cn(locationVal === 'nflrsvp20232' || locationVal === 'nflrsvp202341' || locationVal === 'nflrsvp202342' || locationVal === 'nflrsvp202351' ? 'large' : '')}>
                          {locationVal === 'nflrsvp20231' && 
                            `1 AT&T Way, Arlington, TX 76011`
                          }
                          {locationVal === 'nflrsvp20232' && 
                            `10.29.23 | State Farm Stadium`
                          }  
                          {(locationVal === 'nflrsvp20233' || locationVal === 'nflrsvp202332') && 
                            `11.17.23`
                          }
                          {(locationVal === 'nflrsvp202341' || locationVal === 'nflrsvp202342') &&
                            `12.10.23 | SOFI STADIUM`
                          }
                          {locationVal === 'nflrsvp202351' && 
                            `12.17.23 | NEW YORK CITY`
                          }
                        </span>
                      </div>
                      {locationVal === 'nflrsvp20232' && 
                        <div className="copy">
                          1 Cardinals Dr, Glendale, AZ 85305
                        </div>
                      }
                      {(locationVal === 'nflrsvp202341' || locationVal === 'nflrsvp202342') &&
                        <div className="copy">
                          1001 S. Stadium Drive, Inglewood, CA 90301
                        </div>
                      }

                    </div>
                  </div>
                  {/* <div className="copy" dangerouslySetInnerHTML={{
                    __html: `THE CAPTAIN IS MISSING BUT HE LEFT BEHIND SOME TREASURE<br />
                              Play along for a chance to win prizes and once-in-a-lifetime experiences.
                              You could even score tickets to the big game.`
                    }}
                  /> */}
  		            <h2 className="heading" dangerouslySetInnerHTML={{
                    __html: `${formTitle}`
                    }}
                  />
    		        </div>
      		    </div>

      		    <div className="rightPanel">
    		        <div className="inner">
    		            <div className={cn("formWrapper", formBodyBg !== '' ? `text--white` : '')} id="formWrapper">
    		                <form id="formEnterCode" runat="server" action="/api/CIDBapi_Form">
    		                    <input id="PR_4_367_1_freetext" name="PR_4_367_1_freetext" type="hidden" placeholder="" runat="server"value="" />
    		                    <input id="HD_PromoCode" name="HD_PromoCode" runat="server" type="hidden" value={promoCode} />

    		                    <input id="HD_Consumerid" name="HD_Consumerid" runat="server" type="hidden" value="" />

    		                  <div className="inputWrapper labeled half">
    		                    <label htmlFor="CP_FirstName" className="inputlabel inputlabel--empty">FIRST NAME*</label>
    		                  	<input id="CP_FirstName" name="CP_FirstName" type="text" className="btn--decorated input" required />
    		                  </div>

    		                  <div className="inputWrapper labeled half">
    		                    <label htmlFor="CP_LastName" className="inputlabel inputlabel--empty">LAST NAME*</label>
    		                  	<input id="CP_LastName" name="CP_LastName" type="text" className="btn--decorated input" required />
    		                  </div>

    		                  <div className="inputWrapper labeled half">
    		                    <label htmlFor="CP_EmailId" className="inputlabel inputlabel--empty">EMAIL*</label>
    		                  	<input id="CP_EmailId" name="CP_EmailId" type="email" className="btn--decorated input" required />
    		                  </div>

    		                  <div className="inputWrapper labeled half">
    		                  	<label htmlFor="CP_DOB" className="inputlabel inputlabel--empty">DATE OF BIRTH*</label>
                            <input id="CP_DOB" name="CP_DOB" type="date" className="btn--decorated input" required />
                                <div id="dob__error" className="dob__error">
                                  You must be over 25 to enter. 
                                </div>
    		                  </div>
                          <div className="inputWrapper labeled half">
                            <label htmlFor="PR_4_324_1_freetext" className="inputlabel inputlabel--empty">ZIP CODE*</label>
                            <input id="PR_4_324_1_freetext" name="PR_4_324_1_freetext" className="btn--decorated input" type="text" required />
                          </div>

    		                  <div className="inputWrapper labeled half">
                            <label htmlFor="PR_4_466_1_freetext" className="inputlabel inputlabel--empty">STATE*</label>
                            <select name="PR_4_466_1_freetext" id="PR_4_466_1_freetext" className="input w-input btn--decorated input" defaultValue="" required>
                                <option value="">State*</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District Of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                          </div>

                          {locationVal === 'nflrsvp202351' &&
                            <div className="inputWrapper labeled half">
                              <label htmlFor="team" className="inputlabel inputlabel--empty">Which team are you a fan of? *</label>
                              <select name="team" id="team" className="btn--decorated input"required>
                                <option selected value="">Select team *</option>
                                <option value="jets">NEW YORK JETS</option>
                                <option value="giants">NEW YORK GIANTS</option>
                              </select>
                            </div>
                          }
                          {/*<div className="inputWrapper half">
                            <label htmlFor="plusone">WILL YOU BE BRINGING A PLUS 1</label>
                            <select name="plusone" id="plusone" required>
                              <option disabled selected value="">WILL YOU BE BRINGING A PLUS 1 *</option>
                              <option value="true">YES</option>
                              <option value="false">NO</option>
                            </select>
                          </div>*/}

                          <div className="inputWrapper checkbox ptop">
    		                  	<input id="tc" name="tc" type="checkbox" required />
    		                  	<label htmlFor="tc" dangerouslySetInnerHTML={{
                              __html: `${formTermsCopy}`
                              }}
                            />
    		                  </div>
                          
    		                  <div className="inputWrapper checkbox pbot">
    		                  	<input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" required />
    		                  	<label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                              __html: `${formNewsCopy}`
                              }}
                            />
    		                  </div>

    		                    <div className="inputWrapper">
    		                        <div id="recaptcha"></div>
    		                    </div>

    		                    <div className="inputWrapper">
    		                        <div className="errorMessage" id="errorMessage"></div>
    		                    </div>

    		                  <div className="inputWrapper">
                            <input id="PR_4_92_1" name="PR_4_92_1" runat="server" type="hidden" value={locationVal} />
                            <input id="compname" name="compname" runat="server" type="hidden" value={locationVal} />
                            <input id="entrysource" name="entrysource" runat="server" type="hidden" value={entrysource} />
                            <input id="vip" name="vip" runat="server" type="hidden" value={vipcomp} />
  	                        <input id="HD_IsDefaultFlag" name="HD_IsDefaultFlag" runat="server" type="hidden" value="1" />
  	                        <input id="HD_PR_1_64_1_Brand" name="HD_PR_1_64_1_Brand" runat="server" type="hidden" value="4" />
  	                        <input id="HD_PR_1_64_1_Comm" name="HD_PR_1_64_1_Comm" runat="server" type="hidden" value="1" />
  	                        <input id="HD_AddressType" name="HD_AddressType" runat="server" type="hidden" value="1" />

    		                  	<input className="submit btn--decorated btn--bgwhite btn--textred" type="submit" value="SUBMIT" id='cidbSubmit' onClick={(evnt) => this.registerUser(evnt)} />
    		                  </div>
                          <div className="inputWrapper">
                            <a href={returnLink} className="">
                              Cancel and go back
                            </a>
                          </div>
                           {this.props.compName && this.props.compName === 'nflrsvp20232' &&
                            <div className="inputWrapper copy disclaimer">
                             <small>NO PURCHASE NECESSARY. Phoenix Metro Area only. Must be 25+. RSVP does NOT guarantee ticket to Game: tickets awarded on first come-first served basis to 1st 50 eligible respondents. Must be available on 10/29 @ 4:25 pm ET/2:25 pm MT for Game in Glendale, AZ.  Travel to/from Game & incidentals NOT included.  Unissued/unclaimed tickets not awarded. See <a href="/captain-morgan-nfl-rules.pdf">Rules</a> for full details.</small>
                            </div>
                          }
                          {formDisclaimer && formDisclaimer !== '' &&
                            <div className="inputWrapper copy disclaimer">
                              <small dangerouslySetInnerHTML={{
                              __html: `${formDisclaimer}`
                              }}
                            />
                            </div>
                          }
    		                </form>
    		            </div>

    		        </div>
      		    </div>
            </div>
      		</section>
      	</>
      );

    }

}

export default RsvpComp;
