import "./content-halloween.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";


const ContentTerms = (props) => {
  const pageType = props && props.pagetype ? props.pagetype : ""
  const compName = props && props.compname ? props.compname : ""
  const contentData = getData(pageType, compName)
  
  function getData (pageType, compName) {
    const data = [
      {
        comp: 'hw2023cs',
        data: [
          {
            pagetype: 'terms',
            title: 'Captain Morgan One Hell of a Cocktail Terms and Conditions (the "Promotion")',
            endcopy: 'Promoter: Diageo Great Britain Limited, 16 Great Marlborough St, London W1F 7HS',
            copy: [
              "The Promotion is open to all residents of United Kingdom (excluding Northern Ireland) aged 18 or over.",
              "Employees of the Promoter and its affiliate companies, their families and anyone deemed to be professionally involved with or connected to the Promotion are not permitted to enter the Promotion. ",
              "Purchase and internet access required to enter. A valid bank account is required to claim. ",
              "To enter the Promotion, you must purchase any 70cl or 1L bottle of Captain Morgan Tiki, Captain Morgan Original Spiced Gold, Captain Morgan Sliced Apple, Captain Morgan White Rum, Captain Morgan Dark Rum, Captain Morgan Black Spiced or Captain Morgan 0.0%, see the promotional communication and scan the QR code or visit https://www.captainmorgan.com/en-gb/onehellofacocktail. Enter your details, including First Name, Last Name, Email Address, Mobile Number, the last 4 digits of the product barcode featured on the bottle and select the store you purchased from to be entered into the Prize Draw for the chance to win £6,666 cash prize. ",
              "The prize draw is open for entries between 00:00 on 29th September 2023 and 23:59 3rd November 2023. Entries received outside of this time will be void and will not be entered into the Promotion. The promotion is limited to a maximum of 1 entry per valid mobile number per week. ",
              "There will be one winner. The winner will be the first valid entry electronically drawn at random from all entries correctly submitted in accordance with these terms and conditions. The prize draw will take place within 7 days of the promotion close date and the winner will be notified by the mobile phone number they registered upon entry to the promotion on or before 17th November 2023. An appropriate number of reserves will be drawn at the same time as the winner.",
              "Every reasonable attempt will be made to contact the winner but if the winner does not respond to the notification within 7 days a reserve winner will be contacted by the same process as set out above. ",
              "The winner will receive £6,666 cash prize. A valid bank account is required to claim the prize. The prize will be awarded via bank transfer or via cheque, whichever payment method the winner would prefer.",
              "The prize is non-transferable. The prize will be awarded within 7 days of a valid claim.",
              "The Promoter will use your personal information only in accordance with these terms and conditions and our privacy policy which can be found at https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb. The Promoter will only use your personal information for the purpose of carrying out the Promotion. Where the Promoter is not responsible for the fulfilment of or delivery of the prize, the Promoter shall share your personal information with Umbrella Risk Management who is responsible for the fulfilment of and delivery of the prize.",
              "Subject to paragraph 12 and without prejudice to the Promoter's data protection obligations under relevant law, the Promoter shall make available, upon receipt of a written request (which includes a stamped addressed envelope for such a response) to Captain Morgan One Hell of a Cocktail 7901, PO Box 906, Chilton, Aylesbury, HP22 9HR the winner's surname and county. ",
              "Entrants to the Promotion can, at any time (including at or before the time of entry), object to all or some of their personal information being made available in accordance with paragraph 11 by writing to consumercare.GBandIreland@diageo.com. However, the Promoter shall provide the information set out in paragraph 12 upon request from such competent authorities (including the Advertising Standards Authority).",
              "The winner may be asked to take part in publicity.",
              "The Promoter reserves the right to withdraw, amend or terminate (temporarily or permanently) these terms and conditions or the Promotion with or without prior notice where it becomes necessary to do so due to any unforeseen circumstances outside their reasonable control (including, without limitation, in the case of anticipated, suspected, or actual fraud or in the event of technical or other difficulties that might compromise its integrity).",
              "Entries that contain any offensive or inappropriate content will be deleted and disqualified at the Promoter's discretion. ",
              "No correspondence will be entered into concerning the result and the Promoter’s decision in all matters relating to the Promotion is final.",
              "The Promoter and their agents accept no responsibility for entries that are not received for any reason or difficulties experienced in submitting an entry to this Promotion and proof of submission will not be accepted as proof of delivery.",
              "While nothing in these terms and conditions will limit the Promoter’s liability for death or personal injury caused by its negligence or for fraud, the Promoter will not be legally responsible to entrants or winners for any losses that were not foreseeable to the Promoter or to the entrant at the time of entry to the Promotion or which are caused by a third party.",
              "This Promotion and these terms and conditions are governed by English law and subject to the exclusive jurisdiction of the English Courts. ",
              "Entrants are deemed to have accepted these terms and conditions by participating in this Promotion. ",
            ]
          }
        ]
      },
      {
        comp: 'hw2023ws',
        data: [
          {
            pagetype: 'terms',
            title: 'Captain Morgan One Hell of a Bonus – Buy 12 Bottles or 2 Cases for a Chance to win £6,666 – Terms and Conditions (the "Promotion")',
            endcopy: 'Promoter: Diageo Great Britain Limited, 16 Great Marlborough St, London W1F 7HS.',
            copy: [
              "The Promotion is open to all registered GB independent convenience store owners aged 18 or over.",
              "Employees of the Promoter and its affiliate companies, their families and anyone deemed to be professionally involved with or connected to the Promotion are not permitted to enter the Promotion. ",
              "Purchase and internet access required to enter. You must be the owner of an independent convenience store in Great Britain in order to claim the prize. ",
              "To enter the Promotion, you must purchase any 12 bottles or 2 cases of 70cl or 1L Captain Morgan Tiki, Captain Morgan Spiced Gold, Captain Morgan White Rum, Captain Morgan Dark Rum, Captain Morgan Sliced Apple, Captain Morgan Dark Spiced or Captain Morgan 0.0%  between 00:00 1st October 2023 and 23:59 on 1st November 2023 and scan the QR code or visit https://www.captainmorgan.com/en-gb/ws-onehellofacocktail. You need to complete the online entry form to be entered into the Prize Draw for the chance to win a £6,666 cash prize. Entrants will be required to complete their First Name, Last Name, Your Convenience Store Name, Email Address, Mobile Number, a valid Date of Birth and upload a copy of their itemised wholesaler receipt/invoice showing purchase of the qualifying cases/bottles in one transaction to be entered into the prize draw. ",
              "Purchase of any 12 qualifying bottles or two qualifying cases required. Purchase must be shown to be within the promotional period and in one transaction on the uploaded receipt/invoice to qualify. Uploads must be a maximum of 10mb. Please retain a copy of all relevant invoices/receipts as they will be required to claim your prize. Each invoice/receipt can only be used for a maximum of one entry. ",
              "There is one prize draw open for entries between 00:00 on 1st October 2023 and 23:59 1st November 2023. One draw from all valid entries. Entries received outside of this time will be void and will not be entered into the Promotion. The promotion is limited to a maximum of 1 entry per valid mobile number per week. ",
              "There is 1 (one) prize to be won, consisting of £6,666. Limited to a maximum of one prize per convenience store.",
              "There will be 1 (one) winner. The winner will be the first valid entry electronically drawn at random from all entries correctly submitted in accordance with these terms and conditions. The prize draw will take place within 7 days of the promotion close date and the winner will be notified by the mobile phone number they registered upon entry to the promotion on or before 7th November 2023. An appropriate number of reserves will be drawn at the same time as the winner.",
              "Every reasonable attempt will be made to contact the winner but if the winner does not respond to the notification within 7 days, a reserve winner will be contacted by the same process as set out above in clause 8. ",
              "The winner must be the owner of a registered GB convenience store and will receive a payment of £6,666. The prize will be awarded to the shop owner only. This can be awarded by BACS or cheque and must be made payable to the registered business account. ",
              "The prize is non-transferable. The winner must be able to prove they are a registered GB convenience store owner in order to claim the prize.",
              "The Promoter will use your personal information only in accordance with these terms and conditions and our privacy policy which can be found at https://footer.diageohorizon.com/dfs/assets/www.diageo.com/PrivacyPolicy_en.html?locale=en-gb. The Promoter will only use your personal information for the purpose of carrying out the Promotion. Where the Promoter is not responsible for the fulfilment of or delivery of the prize, the Promoter shall share your personal information with Umbrella Risk Management who is responsible for the fulfilment of and delivery of the prize.",
              "The convenience store winner may be required to declare their prize win to their accountant and there may be a tax liability. The Promoter is not liable for any tax implications that arise from accepting the prize. ",
              "The winner will be expected to take part in reasonable publicity.",
              "The Promoter reserves the right to withdraw, amend or terminate (temporarily or permanently) these terms and conditions or the Promotion with or without prior notice where it becomes necessary to do so due to any unforeseen circumstances outside their reasonable control (including, without limitation, in the case of anticipated, suspected or actual fraud or in the event of technical or other difficulties that might compromise its integrity).",
              "Entries that contain any offensive or inappropriate content will be deleted and disqualified at the Promoter's discretion. ",
              "No correspondence will be entered into concerning the result and the Promoter’s decision in all matters relating to the Promotion is final.",
              "The Promoter and their agents accept no responsibility for entries that are not received for any reason or difficulties experienced in submitting an entry to this Promotion and proof of submission will not be accepted as proof of delivery.",
              "While nothing in these terms and conditions will limit the Promoter’s liability for death or personal injury caused by its negligence or for fraud, the Promoter will not be legally responsible to entrants or winners for any losses that were not foreseeable to the Promoter or to the entrant at the time of entry to the Promotion or which are caused by a third party.",
              "This Promotion and these terms and conditions are governed by English law and subject to the exclusive jurisdiction of the English Courts. ",
              "Entrants are deemed to have accepted these terms and conditions by participating in this Promotion. ",
            ]
          }
        ]
      }
    ]

    if(pageType !== '' && compName !== '') {
      const heroData = data.filter(x => x.comp === compName)[0]
      const heroItems = heroData.data.filter(y => y.pagetype === pageType)[0]
      if(heroItems) {
        return heroItems
      }else{
        return null
      }
    }else{
      return null
    }
  }
  useEffect(() => {

  })
  return (
    <div className="hw2023content__terms terms">
      <div className="container container--1200">
        <div className="terms__title">
          {contentData && contentData.title &&
            contentData.title
          }
        </div>
        <div className="terms__list">
          {contentData && contentData.copy && contentData.copy.length > 0 &&
            <ol>
              {contentData.copy.map( (item, x) => {
                return (
                  <li key={`${pageType}_terms_${x}`}>{`${x+1}. ${item}`}</li>
                )
              })}
            </ol>
          }
        </div>
        <div className="terms__promoter">
          {contentData && contentData.endcopy &&
            contentData.endcopy
          }
        </div>
      </div>
    </div>
  );
};

export default ContentTerms;
