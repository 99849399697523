import mapPin from 'global/images/maps/map_pin.svg'

const token = 'pk.eyJ1Ijoic3RpcnNvIiwiYSI6ImNscTQxbGk4dzAyZnoybHBlYmczNGdmdjUifQ.Y4UPgMEyNf-QUmrveyWzhg'
let map
let markers = []

// delete markers
function clearMarkers () {
  markers.forEach(function (marker) {
    (marker).remove()
  })
  markers = []
}

export const init = () => {
  mapboxgl.accessToken = token
  map = new mapboxgl.Map({
    container: 'map', // container ID
    style: 'mapbox://styles/mapbox/standard', // style URL
    center: [-82.5366803, 27.9946096], // starting position [lng, lat]
    zoom: 9, // starting zoom
  });
  console.log("TEST")
}

export const flyToMarker = (lat, lon) => {
  if(lat && lon) {
    map.flyTo({
      zoom: 12,
      bearing: 5,
      speed: 2,
      center: [Number(lon), Number(lat)]
    })
  }
}

export const createMarkers = (loc, numbers) => {
  console.log('CREATE MARKERS > ', loc)
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [32, 32]
      },
      geometry: {
        type: 'Point',
        coordinates: ([Number(loc.loclon), Number(loc.loclat)])
      }
    }
  }

  // variables for marker popups
  const locName = loc.locname ? loc.locname : ''
  const locAddress = loc.locaddress ? loc.locaddress : ''
  const locZip = loc.loczipcode ? loc.loczipcode : ''

  // creates inner html for marker popup
  let infoWindowTag = '<dl id="' + numbers + '" class="locationItem" style="cursor: pointer; background-color: #fff; border-radius: 3px;">'
  infoWindowTag += '<dd class="locationName">' + locName + '</dd>'
  infoWindowTag += '<dd class="locationAddress">' + locAddress + '</dd>'
  infoWindowTag += '<dd class="locationDirections"><a target="_blank" href="https://maps.google.com?saddr=Current+Location&daddr=' + locAddress + ' ' + locZip + '" class="">Directions</a></dd></dl>'

  // add markers to map
  var markerIcon = document.createElement('div')
  markerIcon.style.backgroundImage = 'url("' + mapPin + '")'
  markerIcon.style.width = geojson.features.properties.iconSize[0] + 'px'
  markerIcon.style.height = geojson.features.properties.iconSize[1] + 'px'
  markerIcon.style.lineHeight = geojson.features.properties.iconSize[1] + 'px'
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const popup = new mapboxgl.Popup()
    .setHTML(infoWindowTag)

  // push each marker into a global array
  // so that they can can be deleted later on
  markers.push(new mapboxgl.Marker(markerIcon)
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map))
}