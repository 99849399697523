import Components from "./components";
import React from "react";
import {renderToString} from "react-dom/server";

const renderElement = (componentName, props, position) => {

    if (componentName in Components) {

        const Component = Components[componentName];

        return <Component key={`${componentName}-${position}`} {...props} />;

    }

    return null;

};

export default renderElement;
