import React from "react";
import "./styled-icon.scss";
import {getIconName, getPrefix} from "global/utils/fontAwesomeLookup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'

const getIcon = (icon) => {

    if (!icon) {
      return null;
    }
    
    const iconName = getIconName(icon);

    if(iconName === 'twitter') {
      return <FontAwesomeIcon icon={faXTwitter} />
    }

    return <FontAwesomeIcon
        icon={{
          iconName,
          prefix: getPrefix(iconName),
        }}
      />;
};

export default ({icon}) => <div className="styled-icon">
    <div className="styled-icon__icon">
        {getIcon(icon)}
    </div>
</div>;
